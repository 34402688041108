// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function ProductCell({ name }) {
  return (
    <SoftBox display="flex" alignItems="center" pr={2}>
      <SoftTypography variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
    </SoftBox>
  );
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ProductCell;
