const profilesListData = [
  {
    name: "Risk Need",
    description: "• Required Rate of Return (%) <br>" +
        "• Market Risk Environment <br>" +
        "• Consequence of Failure ",
    action: {
      type: "internal",
      route: "/pages/users/profile",
      color: "info",
      label: "edit",
    },
  },
  {
    name: "Risk Ability",
    description: "• Need for Liquidity <br>" +
        "• Risk Capacity ",
    action: {
      type: "internal",
      route: "/pages/users/profile",
      color: "info",
      label: "edit",
    },
  },
  {
    name: "Behavioural Risk to Loss",
    description: "• Financial Knowledge <br>" +
        "• Past Reaction ",
    action: {
      type: "internal",
      route: "/pages/users/profile",
      color: "info",
      label: "edit",
    },
  },
];

export default profilesListData;
