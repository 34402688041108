import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Images
import logoGoogle from "assets/images/small-logos/logo-googlecloud.svg";
import logoSchwab from "assets/images/small-logos/logo-schwab.svg";

function Accounts() {
  const [google2FA, setGoogle2FA] = useState(false);
  const [schwab2FA, setSchwab2FA] = useState(false);

  const handleSetGoogle2FA = () => setGoogle2FA(!google2FA);
  const handleSetSchwab2FA = () => setSchwab2FA(!schwab2FA);

  return (
    <Card id="accounts">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Accounts</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Here you can setup and manage your integration settings.
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={2} pb={3} px={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <SoftBox display="flex" alignItems="center">
            <SoftAvatar src={logoGoogle} alt="Google logo" variant="rounded" />
            <SoftBox ml={2}>
              <SoftTypography variant="h5" fontWeight="medium">
                Google Cloud
              </SoftTypography>
              <SoftBox display="flex" alignItems="flex-end">
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  Show less
                </SoftTypography>
                <SoftTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
                  <Icon fontSize="small">expand_less</Icon>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <SoftBox lineHeight={0} mx={2}>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {google2FA ? "Enabled" : "Disabled"}
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={1}>
              <Switch checked={google2FA} onChange={handleSetGoogle2FA} />
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <SoftBox ml={2} pl={6} pt={2} lineHeight={1}>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            You haven&apos;t added your Cloud Backup yet or you aren&apos;t authorized. Please add our
            Google Cloud to your account by clicking on here. When you&apos;ve added the bot, send your
            verification code that you have received.
          </SoftTypography>
          <SoftBox
            bgColor="grey-100"
            borderRadius="lg"
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            my={3}
            py={1}
            pl={{ xs: 1, sm: 2 }}
            pr={1}
          >
            <SoftTypography variant="button" fontWeight="medium" color="text">
              Verification Code
            </SoftTypography>
            <SoftBox width={{ xs: "100%", sm: "25%", md: "15%" }} mt={{ xs: 1, sm: 0 }}>
              <Tooltip title="Copy" placement="top">
                <SoftInput size="small" value="" />
              </Tooltip>
            </SoftBox>
          </SoftBox>
          <SoftBox
            bgColor="grey-100"
            borderRadius="lg"
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            my={3}
            py={1}
            pl={{ xs: 1, sm: 2 }}
            pr={1}
          >
            <SoftTypography variant="button" fontWeight="medium" color="text">
              Connect account
            </SoftTypography>
            <SoftBox
              display="flex"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <SoftBox mr={2} mb={{ xs: 1, sm: 0 }} lineHeight={0}>
                <SoftTypography variant="button" fontWeight="medium">
                  {sessionStorage.getItem("email")}
                </SoftTypography>
              </SoftBox>
              <SoftButton variant="gradient" color="error" size="small">
                connect
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Divider />
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
          {/* <SoftBox display="flex" alignItems="center">
            <SoftAvatar src={logoSchwab} alt="Google logo" variant="rounded" />
            <SoftBox ml={2} lineHeight={0}>
              <SoftTypography variant="h5" fontWeight="medium">
                Charles Schwab
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                Broker
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            width={{ xs: "100%", sm: "auto" }}
            mt={{ xs: 1, sm: 0 }}
          >
            <SoftBox lineHeight={0} mx={2}>
              <SoftTypography variant="button" color="text" fontWeight="regular">
                {schwab2FA ? "Enabled" : "Disabled"}
              </SoftTypography>
            </SoftBox>
            <SoftBox mr={1}>
              <Switch checked={schwab2FA} onChange={handleSetSchwab2FA} />
            </SoftBox>
          </SoftBox> */}
        </SoftBox>
        <Divider />
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          flexDirection={{ xs: "column", sm: "row" }}
        >
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default Accounts;
