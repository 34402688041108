import { useState } from "react";

import {useNavigate} from 'react-router-dom'

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

import SoftAlert from "components/SoftAlert";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// NewModel page components
import Header from "layouts/property/units/new-unit/components/Header";
import ModelInfo from "layouts/property/units/new-unit/components/ModelInfo";
import Stocks from "layouts/property/units/new-unit/components/Stocks";
import Bonds from "layouts/property/units/new-unit/components/Bonds";
import Cash from "layouts/property/units/new-unit/components/Cash";
import Other from "layouts/property/units/new-unit/components/Other";

import validations from "layouts/property/units/new-unit/schemas/validations";
import form from "layouts/property/units/new-unit/schemas/form";
import initialValues from "layouts/property/units/new-unit/schemas/initialValues";

import { submitModel } from "../../../../scripts/unit";
import { uuidv4 } from "../../../../scripts/utils";
import SoftTypography from "../../../../components/SoftTypography";

function getSteps() {
  return ["1. Unit Info", "2. Unit 1", "3. Unit 2", "4. Unit 3", "5. Unit 4"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ModelInfo formData={formData} />;
    case 1:
      return <Stocks formData={formData} />;
    case 2:
      return <Bonds formData={formData} />;
    case 3:
      return <Cash formData={formData} />;
    case 4:
      return <Other formData={formData} />;
    default:
      return null;
  }
}

function NewModel() {
  const navigate = useNavigate();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [type, setType] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const types = ["Aggressive", "Moderate", "Conservative"];

  const handleBack = () => setActiveStep(activeStep - 1)
  const handleType = (value) => {
    setType(value)
  }

  const validateForm = (values) => {
    setError('')
    let isValid = true
    if (activeStep == 0 && (!values.name || !(values.stockWeight || values.bondWeight || values.cashWeight))) {
      isValid = false
      setError('Please fill the missing fields.')
    }
    console.log("Valid", isValid)
    return isValid
  }

  const submitForm = async (values) => {
    setSubmitting(true)
    values.id = uuidv4()
    values.totalWeight = values.stockWeight + values.bondWeight + values.cashWeight + values.otherWeight
    values.created = Date.now()
    values.updated = values.created
    submitModel(values)
        .then(function(response) {
          setSubmitting(false);
          navigate('/property/units/unit-list')
        })
        .catch(function(err) {
          setError(err)
          setSubmitting(false);
        });
  };

  const handleSubmit = (values) => {
    values.type = types[type]
    console.log(JSON.stringify(values))
    if(validateForm(values)) {
      if (isLastStep) {
        submitForm(values);
      } else {
        setActiveStep(activeStep + 1);
        setSubmitting(false);
      }
    }
  }

  return (
    <DashboardLayout>
      <Header handleType={handleType} />
      <SoftBox mt={1} mb={20}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
            >
              {({ values, errors }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ overflow: "visible" }}>
                      <SoftBox p={2}>
                        <SoftBox>
                          {getStepContent(activeStep, {
                            values,
                            formField,
                            errors,
                          })}
                          <SoftBox mt={3} width="100%" display="flex" justifyContent="space-between">
                            {activeStep === 0 ? (
                              <SoftBox />
                            ) : (
                              <SoftButton variant="gradient" color="secondary" onClick={handleBack}>
                                back
                              </SoftButton>
                            )}
                            <SoftButton
                              variant="gradient"
                              color="dark"
                              disabled={isSubmitting}
                              type="submit"
                            >
                              {isLastStep ? "submit" : "next"}
                            </SoftButton>
                          </SoftBox>
                          <SoftBox display="flex" justifyContent="center" mt={2} sx={{ width: "100%" }}>
                            {error ? <SoftTypography color="error" fontWeight="light" variant="button">
                              {error.startsWith("Firebase") ? error.replace("Firebase: ", "") : error}
                            </SoftTypography> : ""}
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </Card>
                  </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewModel;
