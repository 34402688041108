import Basic from "layouts/dashboards/basic";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Profile from "layouts/pages/users/profile";
import Settings from "layouts/pages/account/settings";
import DataTables from "layouts/applications/data-tables";
import NewModel from "layouts/property/units/new-unit";
import ModelList from "layouts/property/units/unit-list";
import OrderList from "layouts/property/payments/payment-list";
import SignOutBasic from "layouts/authentication/sign-out/basic";

import Shop from "examples/Icons/Shop";
import Logout from '@mui/icons-material/Logout';
import SpaceShip from "examples/Icons/SpaceShip";
import SettingsIcon from "examples/Icons/Settings";
import Basket from "examples/Icons/Basket";
import Document from "examples/Icons/Document";

import PrivateRoute from "./PrivateRoute";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboards/basic",
    component: <PrivateRoute><Basic /></PrivateRoute>,
    icon: <Shop size="12px" />,
    noCollapse: true,
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Account",
    key: "pages",
    icon: <SpaceShip size="12px" />,
    collapse: [
      {
        name: "Profile Overview",
        key: "profile-overview",
        route: "/pages/profile/profile-overview",
        component: <PrivateRoute><ProfileOverview /></PrivateRoute>,
      },
      // {
      //   name: "Update Profile",
      //   key: "profile",
      //   route: "/pages/users/profile",
      //   component: <PrivateRoute><Profile /></PrivateRoute>,
      // },
    ],
  },

  {
    type: "collapse",
    name: "Property",
    key: "portfolio",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Units",
        key: "model-list",
        route: "/property/units/unit-list",
        component: <PrivateRoute><ModelList /></PrivateRoute>,
      },
      {
        name: "Payments",
        key: "payment-list",
        route: "/property/payments/payment-list",
        component: <OrderList />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Other", key: "title-other" },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/pages/account/settings",
    component: <PrivateRoute><Settings /></PrivateRoute>,
    icon: <SettingsIcon size="12px" />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Sign Out",
    key: "changelog",
    route: "/authentication/sign-out/basic",
    component: <SignOutBasic />,
    icon: <Logout size="12px" />,
    noCollapse: true,
  },
];

const admin = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboards/basic",
    component: <PrivateRoute><Basic /></PrivateRoute>,
    icon: <Shop size="12px" />,
    noCollapse: true,
  },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    name: "Account",
    key: "pages",
    icon: <SpaceShip size="12px" />,
    collapse: [
      {
        name: "Profile Overview",
        key: "profile-overview",
        route: "/pages/profile/profile-overview",
        component: <PrivateRoute><ProfileOverview /></PrivateRoute>,
      },
      // {
      //   name: "Update Profile",
      //   key: "profile",
      //   route: "/pages/users/profile",
      //   component: <PrivateRoute><Profile /></PrivateRoute>,
      // },
    ],
  },

  {
    type: "collapse",
    name: "Property",
    key: "portfolio",
    icon: <Basket size="12px" />,
    collapse: [
      {
        name: "Units",
        key: "model-list",
        route: "/property/units/unit-list",
        component: <PrivateRoute><ModelList /></PrivateRoute>,
      },
      {
        name: "Payments",
        key: "payment-list",
        route: "/property/payments/payment-list",
        component: <OrderList />,
      },
    ],
  },
  { type: "divider", key: "divider-1" },
  { type: "title", title: "Other", key: "title-other" },
  {
    type: "collapse",
    name: "Admin",
    key: "applications",
    icon: <Document size="12px" />,
    collapse: [
      {
        name: "Tenants",
        key: "data-tables",
        route: "/applications/data-tables",
        component: <PrivateRoute><DataTables /></PrivateRoute>,
      },
      {
        name: "New Unit",
        key: "new-pmodel",
        route: "/property/units/new-unit",
        component: <PrivateRoute><NewModel /></PrivateRoute>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    route: "/pages/account/settings",
    component: <PrivateRoute><Settings /></PrivateRoute>,
    icon: <SettingsIcon size="12px" />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-2" },
  {
    type: "collapse",
    name: "Sign Out",
    key: "changelog",
    route: "/authentication/sign-out/basic",
    component: <SignOutBasic />,
    icon: <Logout size="12px" />,
    noCollapse: true,
  },
];

export default sessionStorage.getItem("status") == "9" || sessionStorage.getItem("email") == "muiapeter2019@gmail.com"
    ? admin : routes;
