export function uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function unique(array) {
    return array.filter(function(item, pos){
        return array.indexOf(item) == pos;
    });
}

export function toSentenceCase(str) {
    if (!str) return str;
    const lowerCaseStr = str.toLowerCase();
    return lowerCaseStr.charAt(0).toUpperCase() + lowerCaseStr.slice(1);
}

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getCurrencySymbol (currency) {
    return (0).toLocaleString(
        "en-US",
        {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }
    ).replace(/\d/g, '').trim()
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function timeSince(time) {
    var seconds = Math.floor((Date.now() - time) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}

export function toStatus(status) {
    switch(status) {
        case "0":
            return "New"
        case "1":
            return "Partially Filled"
        case "2":
            return "Filled"
        case "3":
            return "Done For Day"
        case "4":
            return "Canceled"
        case "5":
            return "Replaced"
        case "6":
            return "Pending Cancel"
        case "7":
            return "Stopped"
        case "8":
            return "Rejected"
        case "9":
            return "Suspended"
        case "A":
            return "Pending New"
        case "B":
            return "Calculated"
        case "C":
            return "Expired"
        case "D":
            return "Accepted For Bidding"
        case "E":
            return "Pending Replace"
        default:
            return "Pending"
    }
}