import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

function Need({ formData }) {
  const { formField, values, errors, touched } = formData;
  const [ consequence, setConsequence  ] = useState(0);
  const [ frequency, setFrequency  ] = useState(0);
  const [ future, setFuture  ] = useState(0);
  const [ horizon, setHorizon  ] = useState(0);
  const [ reason, setReason ] = useState(0);
  const [ ror, setRor  ] = useState(0);
  const [ realistic, setRealistic  ] = useState(0);

  const handleConsequence = (event) => {
    setConsequence(event.target.value);
    values.consequence = event.target.value;
  }
  const handleFrequency = (event) => {
    setFrequency(event.target.value);
    values.frequency = event.target.value;
  }
  const handleFuture = (event) => {
    setFuture(event.target.value);
    values.future = event.target.value;
  }
  const handleHorizon = (event) => {
    setHorizon(event.target.value);
    values.horizon = event.target.value;
  }
  const handleReason = (event) => {
    setReason(event.target.value);
    values.reason = event.target.value;
  }
  const handleRor = (event) => {
    setRor(event.target.value);
    values.ror = event.target.value;
  }
  const handleRealistic = (event) => {
    setRealistic(event.target.value);
    values.realistic = event.target.value;
  }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h3" fontWeight="bold" color="info">
          Get your personalized portfolio
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="regular" mt={0.5} mb={4}>
          {"Hello " + sessionStorage.getItem("firstName") + ", tell us about your need. We will then build a plan that is perfect for you."}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                1. What are you investing for?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={reason} onChange={handleReason}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Retirement</MenuItem>
              <MenuItem value={2}>Housing or Land</MenuItem>
              <MenuItem value={3}>Children Education</MenuItem>
              <MenuItem value={4}>Long term investment growth</MenuItem>
              <MenuItem value={5}>Nothing specific</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                2. What is your future value need ($)?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={future} onChange={handleFuture}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Below $1k</MenuItem>
              <MenuItem value={2}>$1k to $9k</MenuItem>
              <MenuItem value={3}>$10k to $99k</MenuItem>
              <MenuItem value={4}>$100k to $999k</MenuItem>
              <MenuItem value={5}>Above $1m</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                3. What is your investment time horizon?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={horizon} onChange={handleHorizon}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Below 5 years</MenuItem>
              <MenuItem value={2}>Between 5 and 10 years</MenuItem>
              <MenuItem value={3}>Above 10 years</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="h6"
                fontWeight="regular"
                textTransform="capitalize"
              >
                4. What is your frequency of saving / spending?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={frequency} onChange={handleFrequency}>
            <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Monthly</MenuItem>
              <MenuItem value={2}>Quartely</MenuItem>
              <MenuItem value={3}>Yearly</MenuItem>
              <MenuItem value={4}>Occasionally</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                5. What is your estimated required RoR (%) to meet stated goal?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={ror} onChange={handleRor}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Below 1%</MenuItem>
              <MenuItem value={2}>1% to 10%</MenuItem>
              <MenuItem value={3}>10% to 100%</MenuItem>
              <MenuItem value={4}>Above 100%</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                6. Given capital markets expectations and the current market risk environment, is this RoR realistic to obtain?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={realistic} onChange={handleRealistic}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Yes</MenuItem>
              <MenuItem value={2}>No</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="h6"
                fontWeight="regular"
                textTransform="capitalize"
              >
                7. What is the financial consequence of failing to meet your goal?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={consequence} onChange={handleConsequence}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Acceptable</MenuItem>
              <MenuItem value={2}>Uncceptable</MenuItem>
              <MenuItem value={3}>Unknown</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Need
Need.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Need;
