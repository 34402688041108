const form = {
  formId: "new-user-form",
  formField: {
    reason: {
      name: "reason",
      label: "What are you investing for:",
      type: "text",
      placeholder: "eg. Retirement, Housting or Land",
      errorMsg: "Reason for saving is required.",
    },
    frequency: {
      name: "frequency",
      label: "What is your frequency of saving/spending:",
      type: "text",
      placeholder: "eg. Monthly, Quarterly, Yearly, Occasionally",
      errorMsg: "Frequency of saving is required.",
    },
    future: {
      name: "future",
      label: "What is the future value need ($)?:",
      type: "text",
      placeholder: "eg. 1k, 10k, 100k, 1m",
      errorMsg: "Future value need is required.",
    },
    ror: {
      name: "ror",
      label: "What is the estimated required RoR (%) to meet stated goal?:",
      type: "text",
      placeholder: "eg. 1%, 10%, 20%, 50%, 100%",
      errorMsg: "RoR value is required.",
    },
    realistic: {
      name: "realistic",
      label: "Given capital markets expectations and the current market risk environment, is this RoR realistic to obtain?:",
      type: "text",
      placeholder: "eg. 1%, 10%, 20%, 50%, 100%",
      errorMsg: "Realistic value is required.",
    },
    consequence: {
      name: "consequence",
      label: "What is the financial consequence of failing to meet your goal:",
      type: "text",
      placeholder: "eg. Acceptable, Unacceptable, Unknown",
      errorMsg: "Financial consequence is required.",
    },
    horizon: {
      name: "horizon",
      label: "What is your investment time horizon:",
      type: "text",
      placeholder: "eg. A Half, A Third, A Fifth",
      errorMsg: "Horizon is required.",
    },
    income: {
      name: "income",
      label: "What is your monthly income:",
      type: "text",
      placeholder: "eg. $k, $5k, $10k",
      errorMsg: "Income value is required.",
    },
    industry: {
      name: "industry",
      label: "Which industry sector(s) are related to your household income:",
      type: "text",
      placeholder: "eg. Energy, Government, Telecom",
      errorMsg: "Industry value is required.",
    },
    save: {
      name: "save",
      label: "How much of your salary do you save every month:",
      type: "text",
      placeholder: "eg. Half, Third, Fifth",
      errorMsg: "Salary saving is required.",
    },
    willing: {
      name: "willing",
      label: "How much of your income are you willing to lose for a chance of doubling your income?",
      type: "text",
      placeholder: "eg. Half, Third, Fifth",
      errorMsg: "Income loss is required.",
    },
    consistency: {
      name: "consistency",
      label: "How consistent are your earnings over the next 12 months?",
      type: "text",
      placeholder: "eg. Anything can happen, Guarateed",
      errorMsg: "Consistent is required.",
    },
    risky: {
      name: "risky",
      label: "How risky is the stock market:",
      type: "text",
      placeholder: "eg. Very risky, Somewhat risky, Neutral, Somewhat safe, Very safe",
      errorMsg: "Stock market risk is required.",
    },
    past: {
      name: "past",
      label: "In the past, when faced with investment losses, what did you do:",
      type: "text",
      placeholder: "eg. Sold investment, Did nothing, Purchased more",
      errorMsg: "Past experience is required.",
    },
    prefer: {
      name: "past",
      label: "For your $50,000 investment, which of the following hypothetical outcomes would you prefer:",
      type: "text",
      placeholder: "eg. Guarateed, Flip coin",
      errorMsg: "Preference is required.",
    },
  },
};

export default form;
