import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Visibility from "@mui/icons-material/Visibility";

import SoftBox from "components/SoftBox";
import SoftBadgeDot from "components/SoftBadgeDot";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import DataTable from "examples/Tables/DataTable";

// Overview page components
import ModelsChart from "layouts/property/overview/components/ModelsChart";

import {useAuthValue} from "../../../AuthContext";
import {getStock, getStocks} from "../../../scripts/api";
import {getAllModels, getMyModels} from "../../../scripts/unit";
import {getUnits} from "../../../scripts/property";
import {getTenants} from "../../../scripts/tenant";
import {getCurrencySymbol, numberWithCommas} from "../../../scripts/utils";

// Data
import defaultLineChartData from "layouts/property/overview/data/defaultLineChartData";
import horizontalBarChartData from "layouts/property/overview/data/horizontalBarChartData";
import marketTableData from "layouts/property/overview/data/marketTableData";
import dataTableData from "layouts/property/overview/data/dataTableData";

import ProductCell from "../../property/overview/components/ProductCell";
import DefaultCell from "../../property/overview/components/DefaultCell";
import SoftBadge from "../../../components/SoftBadge";
import breakpoints from "../../../assets/theme/base/breakpoints";

function Overview() {
  const {currentUser} = useAuthValue()
  const navigate = useNavigate()
  const [data, setData] = useState({});
  const [docs, setDocs] = useState();
  const [allocation, setAllocation] = useState(horizontalBarChartData);
  const [units, setUnits] = useState({});
  const [performance, setPerformance] = useState(defaultLineChartData);
  const [stocks, setStocks] = useState(marketTableData);
  const [stock, setStock] = useState("AAPL");
  const [mine, setMine] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [allocationLoading, setAllocationLoading] = useState(false);
  const [marketLoading, setMarketLoading] = useState(false);
  const [performanceLoading, setPerformanceLoading] = useState(false);
  const [interval, setInterval] = useState("1D");
  const [tenants, setTenants] = useState([]);
  const [mobileView, setMobileView] = useState(false);
  const [open, setOpen] = useState(false);

  // DefaultStatisticsCard state for the dropdown value
  const [unitsDropdownValue, setUnitsDropdownValue] = useState("");
  const [tenantsDropdownValue, setTenantsDropdownValue] = useState("");
  const [returnsDropdownValue, setReturnsDropdownValue] = useState("");

  // DefaultStatisticsCard state for the dropdown action
  const [unitsDropdown, setUnitsDropdown] = useState(null);
  const [tenantsDropdown, setTenantsDropdown] = useState(null);
  const [returnsDropdown, setReturnsDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openUnitsDropdown = ({ currentTarget }) => setUnitsDropdown(currentTarget);
  const closeUnitsDropdown = ({ currentTarget }) => {
    setUnitsDropdown(null);
    setUnitsDropdownValue(currentTarget.innerText || unitsDropdownValue);
  };
  const openTenantsDropdown = ({ currentTarget }) => setTenantsDropdown(currentTarget);
  const closeTenantsDropdown = ({ currentTarget }) => {
    setTenantsDropdown(null);
    setTenantsDropdownValue(currentTarget.innerText || unitsDropdownValue);
  };
  const openReturnsDropdown = ({ currentTarget }) => setReturnsDropdown(currentTarget);
  const closeReturnsDropdown = ({ currentTarget }) => {
    setReturnsDropdown(null);
    setReturnsDropdownValue(currentTarget.innerText || unitsDropdownValue);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  const toggleDrawer = (open) => (e) => {
      if (e.type === 'keydown')
          return;
      setOpen(open);
    };

  const fetchStock = (symbol, interval) => {
      console.log("fetchStock", symbol)
      getStock(symbol, interval)
          .then(function (response) {
              const labels = []
              const data = []
              for (let i in response.values) {
                  const datetime = response.values[i].datetime;
                  let label = datetime
                  if(datetime.split(" ").length === 2) {
                      const arr = datetime.split(" ")
                      label = arr[1]
                  } else
                  if(datetime.split("-").length === 3) {
                      const arr = datetime.split("-")
                      label = arr[1] +"/" + arr[2]
                  }
                  labels.unshift(label)
                  data.unshift(response.values[i].close)
              }
              setPerformance({
                  labels: labels,
                  datasets: [{
                      label: response.name,
                      color: "info",
                      data: data,
                  }]
              })
              setPerformanceLoading(false)
          })
  }

  const fetch = async () => {
    console.log("fetch", currentUser?.uid)
    setLoading(true)
    setDataLoading(true)
    setAllocationLoading(true)
    setMarketLoading(true)
    setPerformanceLoading(true)

    getUnits(currentUser?.uid)
        .then(function (response) {
          setUnits(response.data())

          if(response.data().tenants) {
              const sorted = response.data().tenants.sort()
              setTenants(sorted)

              const max = 5;
              let symbols = ""
              for (let i in sorted) {
                  if (i < max) symbols += sorted[i] + ","
              }

              getStocks(symbols)
                  .then(function (response) {
                      if (response.data) {
                          response.data.forEach((stock) => {
                              if (stock?.price) marketTableData.rows.push({
                                  symbol: <SoftTypography variant="button" fontWeight="bold"
                                                          color="text">{stock.symbol}</SoftTypography>,
                                  price: <SoftTypography variant="button" fontWeight="bold"
                                                         color="text">{numberWithCommas(stock.price.toFixed(2))}</SoftTypography>,
                                  change: <SoftBadge color="error" badgeContent={stock.change.toFixed(2) + "%"}
                                                     variant="contained"
                                                     size="sm"/>,
                              })
                          })
                          setStocks(marketTableData)
                          setMarketLoading(false)
                      }
                  })

              getTenants(currentUser?.uid)
                  .then(function (response) {
                      let labels = []
                      const data = []
                      response.forEach((doc) => {
                          labels.push(doc.data().symbol)
                          data.push(doc.data().value)
                      })
                      if (labels.length === 0)
                          labels = !sorted || sorted.length ===0
                              ? ["AAPL", "MSFT", "Units"] : sorted
                      console.log(labels)
                      setAllocation({
                          labels: labels.slice(0, 5),
                          datasets: [
                              {
                                  label: "Value",
                                  color: "dark",
                                  data: data.slice(0, 5),
                              },
                          ],
                      })
                      setAllocationLoading(false)
                  })
          }
        })

    dataTableData.rows = []
    marketTableData.rows = []

    getAllModels(5)
        .then(function (all) {
          setDocs(all.docs)

          if (currentUser) try {
            getMyModels(currentUser?.uid)
                .then(function (response) {
                  if (response.docs.length > 0) {
                    setDocs(response.docs)
                    setMine(true)
                  } else {
                    setDocs(all.docs)
                    setMine(false)
                  }
                })
          } catch (err) {
            console.log(err)
            setDocs(all.docs)
            setMine(false)
          } else
            setLoading(false)

            all.forEach((doc) => {
            let count = 0
            for (let i in doc.data().stocks) {
              count++;
            }
            for (let i in doc.data().bonds) {
              count++;
            }
            for (let i in doc.data().cash) {
              count++;
            }
            for (let i in doc.data().other) {
                count++;
            }
            if(mobileView) {
                dataTableData.columns =  [
                    { Header: "model", accessor: "model", width: "50%" },
                    { Header: "", accessor: "buy", align: "center", width: "20px" },
                    { Header: "", accessor: "view", align: "center", width: "20px" },
                ]
            }
            dataTableData.rows.unshift({
              model: <ProductCell name={doc.data().name} tenants={count}/>,
              //description: doc.data().desc,
              weights: <DefaultCell>
                {(doc.data().stockWeight ? doc.data().stockWeight : 0) + " : " +
                (doc.data().bondWeight ? doc.data().bondWeight : 0) + " : " +
                (doc.data().cashWeight ? doc.data().cashWeight : 0) + " : " +
                (doc.data().otherWeight ? doc.data().otherWeight : 0)}
              </DefaultCell>,
              buy: (
                  <SoftButton variant="gradient" color="info" size="small" fullWidth
                              onClick={() => navigate('/property/units/unit-page?id=' + doc.data().id, {
                                state: {
                                  model: doc.data(),
                                  mine: mine
                                }
                              })}>
                    buy
                  </SoftButton>
              ),
              view: (
                  <Visibility cursor="pointer" sx={{width: "20px", height: "20px"}}
                              onClick={() => navigate('/property/units/unit-page?id=' + doc.data().id, {
                                state: {
                                  model: doc.data(),
                                  mine: mine
                                }
                              })}/>
              ),
              data: doc.data()
            })
          });
          setData(dataTableData)
          setDataLoading(false)
        })

    setLoading(false)
  }

  useEffect(() => {
      if (window.innerWidth < breakpoints.values.lg) {
          setMobileView(true);
      } else {
          setMobileView(false);
      }
      if(currentUser?.uid) fetch();
  }, [currentUser])

    useEffect(() => {
        if(currentUser?.uid) fetchStock(stock, interval);
    }, [stock, interval])

  return (
    <DashboardLayout
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="property"
                count={units.value ? (units.currency ? getCurrencySymbol(units.currency) : "$") + numberWithCommas(units.value) : "0"}
                percentage={{
                  color: "success",
                  value: "+0%",
                  label: "since last month",
                }}
                dropdown={{
                  action: openUnitsDropdown,
                  menu: renderMenu(unitsDropdown, closeUnitsDropdown),
                  value: unitsDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="tenants"
                count={units.tenants ?units.tenants.length : 0}
                percentage={{
                  color: "success",
                  value: "+0%",
                  label: "since last month",
                }}
                dropdown={{
                  action: openTenantsDropdown,
                  menu: renderMenu(tenantsDropdown, closeTenantsDropdown),
                  value: tenantsDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Rent"
                count="Ksh 0"
                percentage={{
                  color: "secondary",
                  value: "+ksh 0",
                  label: "since last month",
                }}
                dropdown={{
                  action: openReturnsDropdown,
                  menu: renderMenu(returnsDropdown, closeReturnsDropdown),
                  value: returnsDropdownValue,
                }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <ModelsChart docs={docs}  mine={mine} loading={loading} />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
             <GradientLineChart
                    title="Collection"
                    description={
                      <SoftBox display="flex" justifyContent="space-between">
                        <SoftBox display="flex" ml={-1}>
                          <SoftBadgeDot color="info" size="sm" badgeContent="Collected" />
                          <SoftBadgeDot color="primary" size="sm" badgeContent="Pending" />
                        </SoftBox>
                        <SoftBox display="inline-flex"  mt={-5.25} mr={-1}>
                            { mobileView ? "" :
                            <SoftBox>
                                <SoftButton variant="text" color={interval === '15m' ? "info" : "dark"} sx={{fontWeight: "bold", textTransform: "none"}} onClick={toggleDrawer(true)}>{stock}&nbsp;<Icon>{ open ? "unfold_less" : "unfold_more"}</Icon></SoftButton>
                                <SoftButton variant="text" color={interval === '15m' ? "info" : "dark"} sx={{fontWeight: "regular", textTransform: "none"}} onClick={() => setInterval('15m')}>15m</SoftButton>
                                <SoftButton variant="text" color={interval === '1h' ? "info" : "dark"} sx={{fontWeight: "regular", textTransform: "none"}} onClick={() => setInterval('1h')}>1h</SoftButton>
                                <SoftButton variant="text" color={interval === '1D' ? "info" : "dark"} sx={{fontWeight: "regular", textTransform: "none"}} onClick={() => setInterval('1D')}>1D</SoftButton>
                                <SoftButton variant="text" color={interval === '1W' ? "info" : "dark"} sx={{fontWeight: "regular", textTransform: "none"}} onClick={() => setInterval('1W')}>1W</SoftButton>
                                <SoftButton variant="text" color={interval === '1M' ? "info" : "dark"} sx={{fontWeight: "regular", textTransform: "none"}} onClick={() => setInterval('1M')}>1M</SoftButton>
                            </SoftBox>}
                          <Tooltip title="See how your collections are performing" placement="left" arrow>
                            <SoftButton
                              variant="outlined"
                              color="secondary"
                              size="small"
                              circular
                              iconOnly
                            >
                              <Icon>priority_high</Icon>
                            </SoftButton>
                          </Tooltip>
                        </SoftBox>
                      </SoftBox>
                    }
                    chart={performance}
                    //height="26.125rem"
                  />
            </Grid>
          </Grid>
        </SoftBox>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
                <HorizontalBarChart title="Collection" chart={allocation} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card>
                <SoftBox pt={2} px={3}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Defaulters
                  </SoftTypography>
                </SoftBox>
                <SoftBox py={0.5}>
                  {marketLoading
                      ? <SoftBox display="flex" justifyContent="center" m="auto" mb={2}>
                        <CircularProgress color="info"/>
                      </SoftBox>
                      : <DataTable
                        table={stocks}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                    />}
                </SoftBox>
                <SoftBox display="flex" justifyContent="right" mx={2.1} mb={0.5}>
                  <SoftButton variant="text" color="secondary">see more</SoftButton>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <SoftBox pt={3} px={3}>
                <SoftTypography variant="h6" fontWeight="medium">
                  All Units
                </SoftTypography>
              </SoftBox>
              <SoftBox py={1}>
                {!data.rows
                  ? <SoftBox display="flex" justifyContent="center" m="auto" mb={2}>
                      <CircularProgress color="info"/>
                    </SoftBox>
                  : <DataTable
                        table={data}
                        entriesPerPage={false}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                    />
                }
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
        >
            <SoftBox
                role="presentation"
                onClick={toggleDrawer( false)}
                onKeyDown={toggleDrawer(false)}
                mx={1}
            >
                <SoftTypography variant="h6" color="dark" fontWeight="bold" m={1} mt={3}>Tenants</SoftTypography>
                <Divider />
                <List>
                    {tenants.map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <SoftButton variant="text" color={stock==text ? "info" : "dark"} onClick={()=>setStock(text)}>{text}</SoftButton>
                        </ListItem>
                    ))}
                </List>
            </SoftBox>
        </Drawer>
    </DashboardLayout>
  );
}

export default Overview;
