import {useEffect, useState} from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';
import Switch from "@mui/material/Switch";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

import {signInWithEmailAndPassword, sendEmailVerification, onAuthStateChanged} from 'firebase/auth'
import {useNavigate} from 'react-router-dom'
import {useAuthValue} from '../../../../AuthContext'
import {auth} from '../../../../scripts/firebase'
import {toSentenceCase} from '../../../../scripts/utils'

function Basic() {
    const [rememberMe, setRememberMe] = useState(false);
  
    const handleSetRememberMe = () => setRememberMe(!rememberMe);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('') 
    const {setTimeActive} = useAuthValue()
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user) {
                if(!user.emailVerified) {
                    //navigate('/authentication/sign-out/basic')
                } else
                if(!sessionStorage.getItem('verified')) {
                    //navigate('/authentication/2-step-verification/basic')
                } else
                    navigate('/dashboard/basic')
            }
        })
    }, [])

    const login = e => {
      console.log("Logging in")
      setError('')
     setLoading(true)
      e.preventDefault()
      signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if(!auth.currentUser.emailVerified) {
          sendEmailVerification(auth.currentUser)
          .then(() => {
            setTimeActive(true)
            setLoading(false)
            navigate('/authentication/verify-email/basic')
          })
          .catch(err => {
              setLoading(false)
              setError(
                  err.message.includes("not-found") ? "The account does not exist."
                      : err.message.includes("invalid-email") ? "The email is invalid."
                      : toSentenceCase(err.message.replace("Err ((", "").replace("Firebase: Error (", "").replace("auth/", "").replace(")", "").replace("-", " "))
              )
          })
        } else{
          console.log(auth.currentUser.uid)
          navigate('/authentication/2-step-verification/basic')
        }
      })
      .catch(err => {
          setLoading(false)
          setError(
          err.message.includes("not-found") ? "The account does not exist."
              : toSentenceCase(err.message.replace("Err ((", "").replace("Firebase: Error (", "").replace("auth/", "").replace(")", "").replace("-", " "))
          )
      })
    }
  
    return (
      <BasicLayout>
          <SoftBox p={0} mb={2} textAlign="left">
            <SoftTypography variant="h1" color="info" fontWeight="bold" fontSize="30px">
              Welcome back
            </SoftTypography>
            <SoftTypography variant="p" fontWeight="light" fontSize="1rem">
              Enter your email and password to sign in
            </SoftTypography>
          </SoftBox>
          <SoftBox>
            <SoftBox component="form" role="form">
              <SoftBox mb={2}> 
                <SoftTypography variant="p" fontWeight="bold" fontSize="12px">
                  Email
                </SoftTypography>
                <SoftInput type="email" value={email} onChange={e => setEmail(e.target.value)} required />
              </SoftBox>
              <SoftBox mb={2}> 
                <SoftTypography variant="p" fontWeight="bold" fontSize="12px">
                  Password
                </SoftTypography>
                <SoftInput type="password" value={password} onChange={e => setPassword(e.target.value)} required />
              </SoftBox>
              <SoftBox display="flex" alignItems="center">
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;Remember me
                </SoftTypography>
              </SoftBox>
                {loading
                    ? <SoftBox display="flex" justifyContent="center" m="auto" mt={4}>
                        <CircularProgress color="info"/>
                    </SoftBox>
                    : <SoftBox mt={4} mb={1}>
                    <SoftButton variant="gradient" onClick={login} color="info" disabled={loading}  fullWidth>
                        { loading ? ". . ." : "Sign In"}
                    </SoftButton>
                  </SoftBox> }
                <SoftBox display="flex" justifyContent="center" mt={2} sx={{ width: "100%" }}>
                    {error ? <SoftTypography color="error" fontWeight="light" variant="button">
                        {error.startsWith("Firebase") ? error.replace("Firebase: ", "") : error}
                    </SoftTypography> : ""}
                </SoftBox>
              <SoftBox mt={1} textAlign="center">
                <SoftTypography variant="button" color="dark" fontWeight="regular">
                  Don&apos;t have an account?&nbsp;
                  <SoftTypography
                    component={Link}
                    to="/authentication/sign-up/basic"
                    variant="button"
                    color="info"
                    fontWeight="bold"
                  >
                    Sign Up
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={-1} textAlign="center">
                <SoftTypography variant="button" color="text" fontWeight="regular">
                  <SoftTypography
                    component={Link}
                    to="/authentication/reset-password/basic"
                    variant="button"
                    color="info"
                    fontWeight="bold"
                  >
                    Forgot Password?
                  </SoftTypography>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
      </BasicLayout>
    );
  }

export default Basic;
