import { useState, useEffect } from "react";

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Stack from "@mui/material/Stack";

import ActionCell from "./components/ActionCell";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/property/units/unit-list/data/dataTableData";

import {useAuthValue} from "../../../../AuthContext";
import { getAllModels } from "../../../../scripts/unit";

function ModelList() {
    const {currentUser} = useAuthValue()
    const navigate = useNavigate()
    const [data, setData] = useState({dataTableData});

    const fetch = async () => {
        getAllModels(20)
            .then(function (response) {
                dataTableData.rows = []
                response.forEach((doc) => {
                    dataTableData.rows.unshift({
                        model: [doc.data().name],
                        stocks: doc.data().stockWeight ? doc.data().stockWeight : 0,
                        bonds: doc.data().bondWeight ? doc.data().bondWeight : 0,
                        cash: doc.data().cashWeight ? doc.data().cashWeight : 0,
                        other: doc.data().otherWeight ? doc.data().otherWeight : 0,
                        type: doc.data().type,
                        buy: (
                            <SoftButton variant="gradient" color="info" size="small" fullWidth
                                        onClick={() => navigate('/property/units/unit-page?id=' + doc.data().id, { state: { model: doc.data() }})}>
                                buy
                            </SoftButton>
                        ),
                        action: <ActionCell model={doc.data()} />,
                    })
                    setData(dataTableData)
                });
            })
            .catch(function (err) {
                console.log(err)
            });
    }

    useEffect(() => {
        if(currentUser?.uid) fetch();
    }, [currentUser])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
          {sessionStorage.getItem("status") !== "1" ? ""
          : <Stack spacing={1} direction="row">
              <Link to="/property/units/new-unit">
                <SoftButton variant="gradient" color="info" size="small">
                  + new unit
                </SoftButton>
              </Link>
              <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
            </Stack>}
          </SoftBox>
            {!data.rows
            ? <Card>
                <DataTable table={dataTableData} entriesPerPage={false} canSearch />
              </Card>
            : <DataTable
                table={data}
                entriesPerPage={{
                    defaultValue: 10,
                    entries: [5, 10, 15, 20, 25],
                }}
                canSearch
            />}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ModelList;
