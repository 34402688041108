import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/property/payments/payment-list/data/dataTableData";

import {useAuthValue} from '../../../../AuthContext'
import {getMyOrders} from "../../../../scripts/payment";
import {getCurrencySymbol, numberWithCommas, timeSince, toStatus} from "../../../../scripts/utils";

import ActionCell from "./components/ActionCell";

function OrderList() {
  const {currentUser} = useAuthValue()
  const navigate = useNavigate()
  const [menu, setMenu] = useState(null);
  const [data, setData] = useState(dataTableData);
  const [loading, setLoading] = useState(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  const fetch = async () => {
    setLoading(true)
    getMyOrders(currentUser?.uid)
        .then(function (response) {
          dataTableData.rows = []
          response.forEach((doc) => {
            dataTableData.rows.unshift({
              model: doc.data().model.name,
              date: timeSince(doc.data().created) + ' ago',
              status: doc.data().status,
              weight: (doc.data().model.stockWeight ? doc.data().model.stockWeight : 0) +  " : "
                  + (doc.data().model.bondWeight ? doc.data().model.bondWeight : 0) + " : "
                  + (doc.data().model.cashWeight ? doc.data().model.cashWeight : 0) + " : "
                  + (doc.data().model.otherWeight ? doc.data().model.otherWeight : 0),
              value: (doc.data().currency ? getCurrencySymbol(doc.data().currency) : "$") + numberWithCommas(doc.data().amount),
              action: <ActionCell order={doc.data()} />,
            })
          });
          setData(dataTableData)
          setLoading(false)
        })
        .catch(function (err) {
          console.log(err)
          setLoading(false)
        });
  }

  useEffect(() => {
    if(currentUser?.uid) fetch()
  }, [currentUser])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          {sessionStorage.getItem("status") !== "9" ? ""
          :<SoftButton variant="gradient" color="info" onClick={() => navigate('/property/unit/unit-list')}>
            new order
          </SoftButton>}
          <SoftBox display="flex">
            <SoftButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton>
            {renderMenu}
          </SoftBox>
        </SoftBox>
        <Card>
          {loading
              ? <SoftBox m="auto" mb={2}>
                <CircularProgress color="info" />
              </SoftBox>
              : <DataTable table={data} entriesPerPage={false} canSearch />}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
