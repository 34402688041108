import { db } from './firebase'
import {collection, doc, getDoc, getDocs, setDoc, query, where, orderBy, limit} from "firebase/firestore";

export async function getTenants(uid) {
	if(!uid) uid = sessionStorage.getItem("uid")
	const q = query(collection(db, "users", uid, "tenants"), orderBy("created", "asc"), limit(100))
	return await getDocs(q);
}

export async function getTenant(uid, symbol) {
	if(!uid) uid = sessionStorage.getItem("uid")
	return getDoc(doc(db, "users", uid, "tenants", symbol))
}

export async function exists(uid, id) {
	if(!uid) uid = sessionStorage.getItem("uid")
	const q = query(collection(db, "users", uid, "tenants"), where("ref", "==", id))
	return await getDocs(q);
}

export async function setTenant(uid, values) {
	return await setDoc(doc(db, "users", uid, "tenants", values.symbol), values);
}	
