/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/property/payments/payment-list/components/IdCell";
import DefaultCell from "layouts/property/payments/payment-list/components/DefaultCell";
import StatusCell from "layouts/property/payments/payment-list/components/StatusCell";
import CustomerCell from "layouts/property/payments/payment-list/components/CustomerCell";

const dataTableData = {
  columns: [
    { Header: "name", accessor: "model", Cell: ({ value }) => <IdCell id={value} /> },
    {
      Header: "date",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    { Header: "block", accessor: "value", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "unit",
      accessor: "weight",
      Cell: ({ value }) => {
        const [name, data] = value;

        return (
            <DefaultCell
                value={typeof value === "string" ? value : name}
                suffix={data.suffix || false}
            />
        );
      },
    },
    { Header: "amount", accessor: "action", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;

        if (value === "0") {
          status = <StatusCell icon="done" color="info" status="New" />;
        } else if (value === "1") {
          status = <StatusCell icon="done" color="info" status="Partially Filled" />;
        } else if (value === "2") {
          status = <StatusCell icon="done" color="success" status="Filled" />;
        } else if (value === "3") {
          status = <StatusCell icon="done" color="info" status="Done For Day" />;
        } else if (value === "4") {
          status = <StatusCell icon="close" color="error" status="Cancelled" />;
        } else if (value === "5") {
          status = <StatusCell icon="replay" color="dark" status="Replaced" />;
        } else if (value === "6") {
          status = <StatusCell icon="close" color="warning" status="Pending Cancel" />;
        } else if (value === "7") {
          status = <StatusCell icon="close" color="error" status="Stopped" />;
        } else if (value === "8") {
          status = <StatusCell icon="close" color="error" status="Rejected" />;
        } else if (value === "9") {
          status = <StatusCell icon="close" color="error" status="Suspended" />;
        } else if (value === "A") {
          status = <StatusCell icon="done" color="info" status="Pending New" />;
        } else if (value === "B") {
          status = <StatusCell icon="done" color="dark" status="Calculated" />;
        } else if (value === "C") {
          status = <StatusCell icon="close" color="error" status="Expired" />;
        } else if (value === "D") {
          status = <StatusCell icon="done" color="success" status="Accepted For Bidding" />;
        } else if (value === "R") {
          status = <StatusCell icon="replay" color="info" status="Pending Replace" />;
        } else {
          status = <StatusCell icon="done" color="dark" status="Pending" />;
        }

        return status;
      },
    },
  ],

  rows: [
  ],
};

export default dataTableData;
