import { db } from './firebase'
import {collection, doc, getDocs, setDoc, query, where, orderBy, limit} from "firebase/firestore";
import { getUnits, updateUnit } from './property'
import { setTenant } from './tenant'
import { unique } from './utils'

export async function getAllOrders() {
	const year = new Date().getFullYear()
	const month = new Date().getMonth()
	const q = query(collection(db, "orders", year, month), orderBy("created", "asc"), limit(100))
	return await getDocs(q);
}

export async function exists(uid, id) {
	if(!uid) uid = sessionStorage.getItem("uid")
	const q = query(collection(db, "users", uid, "orders"), where("ref", "==", id))
	return await getDocs(q);
}

export async function getMyOrders(uid) {
	if(!uid) uid = sessionStorage.getItem("uid")
	const q = query(collection(db, "users", uid, "orders"), orderBy("created", "asc"), limit(100))
	return await getDocs(q);
}

export async function submitOrder(values) {
	setDoc(doc(db, "users", values.user, "orders", values.id), values);
	await getUnits(values.user)
		.then(function (response) {
			const tenants = [];
			for(let i in values.model.stocks)
				tenants.push(values.model.stocks[i].tenant[0])
			for(let i in values.model.bonds)
				tenants.push(values.model.bonds[i].tenant[0])
			for(let i in values.model.cash)
				tenants.push(values.model.cash[i].tenant[0])
			for(let i in values.model.other)
				tenants.push(values.model.other[i].tenant[0])
			const units = response.data()
			units.value = units.value + Number(values.amount)
			units.orders = units.orders + 1
			units.tenants = unique(units.tenants.concat(tenants))
			units.updated = Date.now()
			updateUnit(values.user, units)
		})

	if(values.model.stocks) for(let i in values.model.stocks) {
		const tenant = {}
		tenant.symbol = values.model.stocks[i].tenant[0]
		tenant.weight = values.model.stocks[i].weight
		tenant.value = values.model.stocks[i].value
		tenant.created = Date.now()
		setTenant(values.user, tenant)
	}

	if(values.model.bonds) for(let i in values.model.bonds) {
		const tenant = {}
		tenant.symbol = values.model.bonds[i].tenant[0]
		tenant.weight = values.model.bonds[i].weight
		tenant.value = values.model.bonds[i].value
		tenant.created = Date.now()
		setTenant(values.user, tenant)
	}

	if(values.model.cash) for(let i in values.model.cash) {
		const tenant = {}
		tenant.symbol = values.model.cash[i].tenant[0]
		tenant.weight = values.model.cash[i].weight
		tenant.value = values.model.cash[i].value
		tenant.created = Date.now()
		setTenant(values.user, tenant)
	}

	if(values.model.other) for(let i in values.model.other) {
		const tenant = {}
		tenant.symbol = values.model.other[i].tenant[0]
		tenant.weight = values.model.other[i].weight
		tenant.value = values.model.other[i].value
		tenant.created = Date.now()
		setTenant(values.user, tenant)
	}

	const year = new Date().getFullYear()
	const month = new Date().getMonth()
	return await setDoc(doc(db, "orders", year, month, values.id), values);
}	
