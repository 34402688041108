import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";

const marketTableData = {
  columns: [
    { Header: "name", accessor: "symbol", align: "left", width: "40%"},
    { Header: "phone", accessor: "price", align: "center" },
    { Header: "amount", accessor: "change", align: "center" },
  ],
  rows: [
  ]
};

export default marketTableData;
