import { useState } from "react";

import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import { useEffect } from "react";


// @mui material components
import Menu from "@mui/material/Menu";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import {auth} from '../../../scripts/firebase'
import {onAuthStateChanged} from 'firebase/auth'


function DefaultNavbarMobile({ routes, open, close }) {
  const { width } = open && open.getBoundingClientRect();
  const [openCollapse, setOpenCollapse] = useState(false);

  const handleSepOpenCollapse = (name) =>
    openCollapse === name ? setOpenCollapse(false) : setOpenCollapse(name);

  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <SoftBox mb={2}>
        <SoftTypography
          component={Link}
          to="https://mybantu.us"
          fontFamily="Open Sans"
          variant="button"
          color="dark"
          fontWeight="regular"
          p={2}
        >
          Home
        </SoftTypography>
      </SoftBox>
      <SoftBox mb={2}>
        <SoftTypography
          component={Link}
          to={currentUser ? "/authentication/sign-out/basic" : "/authentication/sign-up/basic"}
          fontFamily="Open Sans"
          variant="gradient"
          color="dark"
          fontWeight="regular"
          p={2}
        >
          {currentUser ? "Sign Out" : "Sign Up"}
        </SoftTypography>
      </SoftBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
