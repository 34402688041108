import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// ModelPage page components
import ModelInfo from "layouts/property/units/unit-page/components/ModelInfo";

// Data
import dataTableData from "layouts/property/units/unit-page/data/dataTableData";
import ModelsChart from "../../overview/components/ModelsChart";
import ModelCell from "./components/ModelCell";
import DefaultCell from "./components/DefaultCell";

import {getCurrencySymbol, numberWithCommas} from "../../../../scripts/utils";

function ModelPage() {
  const { state } = useLocation()
  const [ model ] = useState(state?.model)
  const [ mine ] = useState(state?.mine)
  const [ value, setValue ] = useState(0)
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleValue = (value) => {
    setValue(value);

    reload({
      columns: [
        { Header: "symbol", accessor: "symbol", width: "50%" },
        { Header: "weight", accessor: "weight", width: "30%" },
        { Header: "value", accessor: "value", align: "left" },
      ],
      rows: [
      ],
    }, value)
  };

  const reload = (data, value) => {
    setLoading(true)
    if (model) {
      const rows = []
      const total = model.totalWeight ? model.totalWeight
          : model.stockWeight + model.bondWeight + model.cashWeight + model.otherWeight
      for (let i in model.stocks)
        rows.push({
          symbol: <ModelCell name={model.stocks[i].security[0]}/>,
          weight: <DefaultCell>{model.stocks[i].weight}</DefaultCell>,
          value:
              <DefaultCell>{mine ? (model.currency ? getCurrencySymbol(model.currency) : "$") + numberWithCommas(model.stocks[i].value)
                  : value > 0 ?  (model.currency ? getCurrencySymbol(model.currency) : "$") + (numberWithCommas(value * model.stocks[i].weight/total)) : "..."}</DefaultCell>,
        })
      for (let i in model.bonds)
        rows.push({
          symbol: <ModelCell name={model.bonds[i].security[0]}/>,
          weight: <DefaultCell>{model.bonds[i].weight}</DefaultCell>,
          value:
              <DefaultCell>{mine ? (model.currency ? getCurrencySymbol(model.currency) : "$") + numberWithCommas(model.bonds[i].value)
                  : value > 0 ?  (model.currency ? getCurrencySymbol(model.currency) : "$") + (numberWithCommas(value * model.bonds[i].weight/total)) : "..."}</DefaultCell>,
        })
      for (let i in model.cash)
        rows.push({
          symbol: <ModelCell name={model.cash[i].security[0]}/>,
          weight: <DefaultCell>{model.cash[i].weight}</DefaultCell>,
          value:
              <DefaultCell>{mine ? (model.currency ? getCurrencySymbol(model.currency) : "$") + numberWithCommas(model.cash[i].value)
                  : value > 0 ?  (model.currency ? getCurrencySymbol(model.currency) : "$") + (numberWithCommas(value * model.cash[i].weight/total)) : "..."}</DefaultCell>,
        })
      for (let i in model.other)
        rows.push({
          symbol: <ModelCell name={model.other[i].security[0]}/>,
          weight: <DefaultCell>{model.other[i].weight}</DefaultCell>,
          value:
              <DefaultCell>{mine ? (model.currency ? getCurrencySymbol(model.currency) : "$") + numberWithCommas(model.other[i].value)
                  : value > 0 ?  (model.currency ? getCurrencySymbol(model.currency) : "$") + (numberWithCommas(value * model.other[i].weight/total)) : "..."}</DefaultCell>,
        })
      data.rows = rows;
      setData(data)
      setLoading(false)
    }
  }

  useEffect(() => {
    reload(dataTableData, value)
  }, [value])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <SoftBox mb={3}>
              <SoftTypography variant="h5" fontWeight="medium">
              Overview
              </SoftTypography>
            </SoftBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ModelsChart model={model} value={value} mine={mine}  hide={true} />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ModelInfo model={ model } handleValue={handleValue} />
              </Grid>
            </Grid>

            <SoftBox mt={8} mb={2}>
              <SoftBox mb={1} ml={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Tenants
                </SoftTypography>
              </SoftBox>
              {loading || !data.rows
                  ? <SoftBox display="flex" justifyContent="center" m="auto" mt={2} mb={2}>
                    <CircularProgress color="info"/>
                  </SoftBox>
                : <DataTable
                    table={data}
                    entriesPerPage={false}
                    showTotalEntries={true}
                    isSorted={false}
                  />}
            </SoftBox>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ModelPage;
