// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import SoftTypography from "components/SoftTypography";

function DefaultCell({ children }) {
  return (
    <SoftTypography variant="button" color="secondary" fontWeight="regular">
      {children}
    </SoftTypography>
  );
}

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  children: PropTypes.any.isRequired,
};

export default DefaultCell;
