import { auth, db } from './firebase'
import {collection, doc, query, getDocs, setDoc, orderBy, limit} from "firebase/firestore";

export async function getAllModels(max) {
	const q = query(collection(db, "models"), orderBy("created", "asc"), limit(max))
	return await getDocs(q);
}

export async function getMyModels(id) {
	if(!id) id = sessionStorage.getItem("uid")
	const q = query(collection(db, "users", id, "models"), orderBy("created", "asc"), limit(100))
	return await getDocs(q);
}

export async function submitModel(values) {
	return await setDoc(doc(db, "models", values.id), values);
}	
