import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";

import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

import routes from "routes";

import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brand from "assets/images/logos/imageedit_2_5725811381.png";

import {AuthProvider} from './AuthContext'
import {auth} from './scripts/firebase'
import {onAuthStateChanged} from 'firebase/auth'

import Dashboard from './layouts/dashboards/basic';
import Units from './layouts/property/units/unit-list';
import Unit from './layouts/property/units/unit-page';
import Payments from './layouts/property/payments/payment-list';
import Payment from './layouts/property/payments/payment-details';
import ResetPassword from './layouts/authentication/reset-password/basic';
import SignIn from './layouts/authentication/sign-in/basic';
import SignUp from './layouts/authentication/sign-up/basic';
import SignOut from './layouts/authentication/sign-out/basic';
import VerifyEmail from './layouts/authentication/verify-email/basic';
import VerifyPhone from './layouts/authentication/2-step-verification/basic';
import Profile from './layouts/pages/users/profile';
import ProfileOverview from './layouts/pages/profile/profile-overview';
import Settings from './layouts/pages/account/settings';

import PrivateRoute from "./PrivateRoute";

export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const [currentUser, setCurrentUser] = useState(auth?.currentUser)
  const [timeActive, setTimeActive] = useState(false)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log("onAuthStateChanged", user?.uid)
      setCurrentUser(user)
      if(user) sessionStorage.setItem("uid", user.uid)
      setLoading(false);
    })
  }, [])

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  const payments = <Payments />;

  return direction === "rtl" ? (
    <AuthProvider value={{currentUser, timeActive, setTimeActive, loading}}>
      <CacheProvider value={rtlCache}>
        <ThemeProvider theme={themeRTL}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brand}
                brandName="Restoco"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Configurator />
              {configsButton}
            </>
          )}
          {layout === "vr" && <Configurator />}
          <Routes>
            {getRoutes(routes)}
            <Route path="*" element={
              !currentUser || !currentUser?.emailVerified
                  ? <Navigate to="/authentication/sign-in/basic" />
                  : !sessionStorage.getItem('verified')
                      ? <Navigate to="/authentication/2-step-verification/basic" />
                      : <Navigate to='/dashboards/basic' replace/>
            } />
            <Route path='/property' element= {<PrivateRoute> <Units /></PrivateRoute>}/>
            <Route path='/units' element= {<PrivateRoute> <Units /></PrivateRoute>}/>
            <Route path='/property/units/unit-list' element= {<PrivateRoute> <Units /></PrivateRoute>}/>
            <Route path='/property/units/unit-page' element= {<PrivateRoute> <Unit /></PrivateRoute>}/>
            <Route path='/payments' element= {<PrivateRoute> <Payments /></PrivateRoute>}/>
            <Route path='/property/payments/payment-list' element= {<PrivateRoute> <Payments /></PrivateRoute>}/>
            <Route path='/property/payments/payment-details' element= {<PrivateRoute> <Payment /></PrivateRoute>}/>
            <Route path='/pages/users/profile' element= {<PrivateRoute> <Profile /></PrivateRoute>} />
            <Route path='/account' element= {<PrivateRoute> <ProfileOverview /></PrivateRoute>} />
            <Route path='/profile' element= {<PrivateRoute> <ProfileOverview /></PrivateRoute>} />
            <Route path='/pages/profile/profile-overview' element= {<PrivateRoute> <ProfileOverview /></PrivateRoute>} />
            <Route path='/pages/account/settings' element= {<PrivateRoute> <Settings /></PrivateRoute>} />
            <Route path='/authentication/reset-password/basic' element={<ResetPassword/>} />
            <Route path='/authentication/sign-in/basic' element={<SignIn/>} />
            <Route path='/authentication/sign-up/basic' element={<SignUp/>} />
            <Route path='/authentication/sign-out/basic' element={<SignOut/>} />
            <Route path='/authentication/verify-email/basic' element={<VerifyEmail/>} />
            <Route path='/authentication/2-step-verification/basic' element={<VerifyPhone/>} />
          </Routes>
        </ThemeProvider>
      </CacheProvider>
    </AuthProvider>
  ) : (
    <AuthProvider value={{currentUser, timeActive, setTimeActive, loading}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={brand}
              brandName="InvestBantu, Inc."
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={
            !currentUser || !currentUser?.emailVerified
                ? <Navigate to="/authentication/sign-in/basic" />
                : !sessionStorage.getItem('verified')
                    ? <Navigate to="/authentication/2-step-verification/basic" />
                    : <Navigate to='/dashboards/basic' replace/>
          } />
          <Route path='/dashboards/basic' element= {<PrivateRoute> <Dashboard /> </PrivateRoute>}/>
          <Route path='/property' element= {<PrivateRoute> <Units /></PrivateRoute>}/>
          <Route path='/units' element= {<PrivateRoute> <Units /></PrivateRoute>}/>
          <Route path='/property/units/unit-list' element= {<PrivateRoute> <Units /></PrivateRoute>}/>
          <Route path='/property/units/unit-page' element= {<PrivateRoute> <Unit /></PrivateRoute>}/>
          <Route path='/payments' element= {<PrivateRoute> <Payments /></PrivateRoute>}/>
          <Route path='/property/payments/payment-list' element= {<PrivateRoute> <Payments /></PrivateRoute>}/>
          <Route path='/property/payments/payment-details' element= {<PrivateRoute> <Payment /></PrivateRoute>}/>
          <Route path='/pages/users/profile' element= {<PrivateRoute> <Profile /></PrivateRoute>} />
          <Route path='/account' element= {<PrivateRoute> <ProfileOverview /></PrivateRoute>} />
          <Route path='/profile' element= {<PrivateRoute> <ProfileOverview /></PrivateRoute>} />
          <Route path='/pages/profile/profile-overview' element= {<PrivateRoute> <ProfileOverview /></PrivateRoute>} />
          <Route path='/pages/account/settings' element= {<PrivateRoute> <Settings /></PrivateRoute>} />
          <Route path='/authentication/reset-password/basic' element={<ResetPassword/>} />
          <Route path='/authentication/sign-in/basic' element={<SignIn/>} />
          <Route path='/authentication/sign-up/basic' element={<SignUp/>} />
          <Route path='/authentication/sign-out/basic' element={<SignOut/>} />
          <Route path='/authentication/verify-email/basic' element={<VerifyEmail/>} />
          <Route path='/authentication/2-step-verification/basic' element={<VerifyPhone/>} />
        </Routes>
      </ThemeProvider>
    </AuthProvider>
  );
}
