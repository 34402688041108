/* eslint-disable react/prop-types */
import SoftBadge from "components/SoftBadge";

// Badges
const aggressive = (
  <SoftBadge variant="contained" color="error" size="xs" badgeContent="Aggressive" container />
);
const moderate = (
    <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Moderate" container />
);
const conservative = (
  <SoftBadge variant="contained" color="success" size="xs" badgeContent="Conservative" container />
);

const dataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "model",
      width: "40%",
    },
    // { Header: "stocks", accessor: "stocks" },
    // { Header: "bonds", accessor: "bonds" },
    // { Header: "cash", accessor: "cash" },
    // { Header: "other", accessor: "other" },
    {
      Header: "type",
      accessor: "type",
      Cell: ({ value }) => (value === "Aggressive" ? aggressive : value === "Moderate" ? moderate : conservative),
    },
    // { Header: "blocks", accessor: "bonds", align: "center", width: "20px" },
    { Header: "rent", accessor: "stocks", align: "center", width: "40px" },
    { Header: "", accessor: "action", width: "20px" },
  ],

  rows: [
  ],
};

export default dataTableData;
