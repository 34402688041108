import { useState, useEffect } from "react";
import {useLocation, useNavigate} from 'react-router-dom'

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";
import SpaceShip from "examples/Icons/SpaceShip";

import pageRoutes from "page.routes";

import {deleteKyc, submitKyc, syncKyc} from "../../../../scripts/kyc";
import { toSentenceCase } from '../../../../scripts/utils'
import { auth, db } from '../../../../scripts/firebase';
import { PhoneAuthProvider, RecaptchaVerifier, linkWithCredential, reauthenticateWithCredential } from 'firebase/auth'

import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import {doc, getDoc} from "firebase/firestore";

import { updateStatus } from '../../../../scripts/kyc'
import {deleteUser} from "../../../../scripts/api";

function Basic() {
  const { state } = useLocation()
  const [ kyc ] = useState(state?.kyc)
  const [code, setCode] = useState(false)
  const [sent, setSent] = useState(false)
  const [recaptcha, setRecaptcha] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    //if (!auth.currentUser)
    //  navigate('/authentication/sign-in/basic')
  }, [])

  const getCode = async () => {
    setError('')
    setLoading(true)

    let phone = ""
    if(auth.currentUser.phoneNumber)
      phone = auth.currentUser.phoneNumber
    else {
      const id = auth.currentUser.uid
      const docRef = doc(db,"users", id, "profile", "kyc")
      const docKyc = await getDoc(docRef)
      phone = docKyc.data()['phone']
      if(phone) {
        if(!phone.startsWith("+")) {
          const country = docKyc.data()['country']
          if(country) {
            const phoneUtil = PhoneNumberUtil.getInstance()
            phone = phoneUtil.format(phoneUtil.parse(phone, country), PhoneNumberFormat.E164)
          }
        }
      } else if(kyc) {
        phone = kyc.phone
        if(!phone.startsWith("+")) {
          const country = kyc.country
          if(country) {
            const phoneUtil = PhoneNumberUtil.getInstance()
            phone = phoneUtil.format(phoneUtil.parse(phone, country), PhoneNumberFormat.E164)
          }
        }
        submitKyc(auth.currentUser.uid, kyc)
      }
    } 
    console.log("Phone", phone)

    if(!phone) {
      setError("Sorry, could not verify your phone.")
      setLoading(false);
      return;
    }

    try {
      if (!recaptcha) {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
          'size': 'invisible',
          'callback': (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
          'expired-callback': () => {
            setError("Captcha Expired. Please try again later.")
          }
        }, auth)
      setRecaptcha(true)
    }

      new PhoneAuthProvider(auth).verifyPhoneNumber(phone, window.recaptchaVerifier)
      .then((verificationId) => {
        console.log("Code has been sent: id=" + verificationId)
        window.verificationId = verificationId;
        setSent(true)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        setError(err.message)
        setLoading(false)
        if(err.message.includes("invalid-phone-number")) {
          const uid = auth.currentUser.uid
          console.log("Delete uid=" + uid + ", error=" + err.message)
          deleteUser(uid)
              .then((response) => {
                deleteKyc(uid)
                navigate('/authentication/sign-up/basic')
                alert("Invalid phone or country")
              }).catch((err) => {
            console.log("Delete", uid + ", " + err.message)
          })
        }
        setError(
            err.message.includes("not-found") ? "The account does not exist."
                : err.message.includes("invalid-email") ? "The email is invalid."
                : err.message.includes("invalid-app-credential") ? "Please try again in a few seconds."
                : err.message.includes("many-requests") ? "Too many requests. Please try again later."
                : toSentenceCase(err.message.replace("Err ((", "").replace("Firebase: Error (", "").replace("auth/", "").replace(")", "").replace("-", " "))
        )
      });
    }  catch(err) {
      console.log(err)
      setError( "Please try again in a few seconds.")
      setLoading(false)
    }
  };

  const verifyPhone = e => {
    console.log("Verify Phone: code=" + code)
    setLoading(true);
    setError('')
    const credential = PhoneAuthProvider.credential(
      window.verificationId,
      code
    )
    if(!auth.currentUser.phoneNumber) {
      linkWithCredential(auth.currentUser, credential).then((result) => {
        // User signed in successfully.
        next(result.user)
      }).catch((err) => {
        setError(
            err.message.includes("not-found") ? "The account does not exist."
                : err.message.includes("invalid-email") ? "The email is invalid."
                : err.message.includes("invalid-app-credential") ? "Please try again in a few seconds."
                : err.message.includes("many-requests") ? "Too many requests. Please try again later."
                : toSentenceCase(err.message.replace("Err ((", "").replace("Firebase: Error (", "").replace("auth/", "").replace(")", "").replace("-", " "))
        )
        setLoading(false)
      });
    } else{
      reauthenticateWithCredential(auth.currentUser, credential).then((result) => {
        // User signed in successfully.
        next(result.user)
      }).catch((err) => {
        setError(
            err.message.includes("not-found") ? "The account does not exist."
                : err.message.includes("invalid-email") ? "The email is invalid."
                : err.message.includes("invalid-app-credential") ? "Please try again in a few seconds."
                : err.message.includes("many-requests") ? "Too many requests. Please try again later."
                : toSentenceCase(err.message.replace("Err ((", "").replace("Firebase: Error (", "").replace("auth/", "").replace(")", "").replace("-", " "))
        )
        setLoading(false)
      });
    }
  }

  const next = async (user) => {
    const id = user? user.uid : auth.currentUser.uid
    const docRef = doc(db,"users", id, "profile", "kyc")
    const kyc = await getDoc(docRef)
    const status = kyc.data()['status']
    console.log("Status", status)

    updateStatus(id, 1)
    syncKyc(user)
    sessionStorage.setItem('verified', "true")

    if(!status || status === 0) {
      console.log("Complete profile")
      navigate('/dashboards/basic')
    } else
      navigate('/dashboards/basic')

    setError('')
    setLoading(false)
  }

  return (
    <PageLayout>
      <DefaultNavbar
        routes={pageRoutes}
        transparent
        light
      />
      <SoftBox position="relative" width="100%" height="100vh" sx={{ overflow: "hidden" }}>
        <Grid
          container
          spacing={10}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} md={6} lg={4}>
              <SoftBox textAlign="center" p={2}>
                <SoftBox
                  display="grid"
                  justifyContent="center"
                  alignItems="center"
                  width="6.25rem"
                  height="6.25rem"
                  borderRadius="50%"
                  shadow="md"
                  fontSize="3rem"
                  color="white"
                  bgColor="info"
                  variant="gradient"
                  mx="auto"
                  mb={4}
                >
                  <SpaceShip color="white" size="35px" />
                </SoftBox>
                <SoftBox mb={3} px={1}>
                  <SoftTypography variant="h3" fontWeight="bold">
                    Security verification
                  </SoftTypography>
                  <SoftTypography variant="h6" fontWeight="light" >
                    {sent ? "Please enter the code" : "Phone verification code"}
                  </SoftTypography>
                </SoftBox>
                <SoftBox mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs>
                      <FormControl variant="outlined" sx={{ padding: 0, maxWidth: "380px", width: "100%" }}>
                        <OutlinedInput
                          id="code"
                          type='text'
                          onChange={(newValue) => {
                            setCode(newValue.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              {loading ? <CircularProgress color="info" />
                              : sent ? ""
                              : <Typography
                                variant="button"
                                onClick={getCode}
                                edge="end"
                                style={{ cursor: 'pointer' }}
                              >
                               Get Code
                              </Typography>}
                            </InputAdornment>
                          }
                          sx={{
                            textAlign: "center",
                            width: "100%",
                            ".MuiInputBase-root-MuiOutlinedInput-root": {
                              margin: "0px !important",
                              padding: "0px !important",
                            },
                            ".MuiInputBase-input-MuiOutlinedInput-input": {
                              margin: "0px !important",
                              padding: "0px !important",
                            }
                          }}
                          label=""
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox mb={2} mx="auto" sx={{ maxWidth: "380px", width: "100%" }}>
                  <SoftButton onClick={verifyPhone} variant="gradient" color="info" disabled={!sent || loading} fullWidth>
                    { loading ? ". . ." : "Verify"}
                  </SoftButton>
                </SoftBox>
                <SoftBox display="flex" justifyContent="center" mb={0.5} sx={{ width: "100%" }}>
                  {error ? <SoftTypography color="error" fontWeight="light" variant="button">
                    {error.startsWith("Firebase") ? error.replace("Firebase: ", "") : error}
                  </SoftTypography> : ""}
                </SoftBox>
                <SoftBox mt={1} textAlign="center">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                    Haven&apos;t received it?{" "}
                    <SoftButton component="a" color="info" variant="text" onClick={getCode}>
                      Resend
                    </SoftButton>
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
          </Grid>
        </Grid>
        <SoftBox
            id="recaptcha"
            data-sitekey="6LcWRP4nAAAAANot-AwB6jxvGIHGcn1fMY-eQ0rS"
            sx={{display: "none"}}
          />
      </SoftBox>
    </PageLayout>
  );
}

export default Basic;
