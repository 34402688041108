// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

function ProductCell({ name, tenants }) {
  return (
    <SoftBox display="flex" alignItems="center" pr={2}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="button" fontWeight="medium" color="secondary">
          <SoftTypography component="span" variant="button" color="success">
            {tenants}
          </SoftTypography>{" "}
          tenants
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the ProductCell
ProductCell.propTypes = {
  name: PropTypes.string.isRequired,
  tenants: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default ProductCell;
