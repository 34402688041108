import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";


function Ability({ formData }) {
  const { formField, values, errors, touched } = formData;
  const [ consistency, setConsistency  ] = useState(0);
  const [ income, setIncome  ] = useState(0);
  const [ industry, setIndustry  ] = useState(0);
  const [ save, setSave  ] = useState(0);
  const [ willing, setWilling  ] = useState(0);

  const handleConsistency = (event) => {
    setConsistency(event.target.value);
    values.consistency = event.target.value;
  }

  const handleIncome = (event) => {
    setIncome(event.target.value);
    values.income = event.target.value;
  }

  const handleIndustry = (event) => {
    setIndustry(event.target.value);
    values.industry = event.target.value;
  }

  const handleSave = (event) => {
    setSave(event.target.value);
    values.save = event.target.value;
  }

  const handleWilling = (event) => {
    setWilling(event.target.value);
    values.willing = event.target.value;
  }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h3"  fontWeight="bold" color="info">
          Get your personalized portfolio
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="regular" mt={0.5} mb={4}>
          {"Hello " + sessionStorage.getItem("firstName") + ", tell us about your ability. We will then build a plan that is perfect for you."}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="h6"
                 fontWeight="regular"
                textTransform="capitalize"
              >
                8. What is your (entire household) monthly income?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={income} onChange={handleIncome}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Below $1k</MenuItem>
              <MenuItem value={2}>$1k to $4k</MenuItem>
              <MenuItem value={3}>$5k to $9k</MenuItem>
              <MenuItem value={4}>$10k and above</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                9. How much of your income do you save every month?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={save} onChange={handleSave}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Below 20%</MenuItem>
              <MenuItem value={2}>Between 20% To 33%</MenuItem>
              <MenuItem value={3}>Between 33% to 50%</MenuItem>
              <MenuItem value={4}>Above 50%</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                10. How much of your income are you willing to lose for a chance of doubling your income?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={willing} onChange={handleWilling}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>A Half</MenuItem>
              <MenuItem value={2}>A Third</MenuItem>
              <MenuItem value={3}>A Fifth</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                11. How consistent are your earnings over the next 12 months?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={consistency} onChange={handleConsistency}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Pay will likely grow with time, but anything can happen.</MenuItem>
              <MenuItem value={2}>Pay varies quite a bit or a large portion of it is bonus/commission.</MenuItem>
              <MenuItem value={3}>Pay is basically guaranteed (even in retirement)</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                12. Which industry sector is related to your household income?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={industry} onChange={handleIndustry}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Financials/Consumer Services and Retail</MenuItem>
              <MenuItem value={2}>Industrials/Technology</MenuItem>
              <MenuItem value={3}>Health care or food and household staples</MenuItem>
              <MenuItem value={4}>Energy or Materials</MenuItem>
              <MenuItem value={5}>Telecom or Utilities</MenuItem>
              <MenuItem value={6}>Government</MenuItem>
              <MenuItem value={7}>Non-Profit</MenuItem>
              <MenuItem value={8}>Real Estate</MenuItem>
              <MenuItem value={9}>Retired</MenuItem>
              <MenuItem value={10}>Other</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Ability
Ability.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Ability;
