import {useState} from "react";
import {useNavigate} from "react-router-dom";

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Popover from '@mui/material/Popover';

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import SoftInput from "../../../../../../components/SoftInput";
import {getCurrencySymbol, uuidv4} from "../../../../../../scripts/utils";

import {useAuthValue} from "../../../../../../AuthContext";
import {exists, submitOrder} from "../../../../../../scripts/payment";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

import React from "react";
import { storage, db } from "./../../../../../../scripts/firebase"; // Import your Firebase configuration


function ModelInfo(data) {
    const [model] = useState(data?.model)
    const [anchorEl, setAnchorEl] = useState()
    const [execute, setExectute] = useState(false)
    const [simulate, setSimulate] = useState(false)
    const [value, setValue] = useState(0)
    const [error, setError] = useState('');
    const [isSubmitting, setSubmitting] = useState(false);
    const {currentUser} = useAuthValue()
    const navigate = useNavigate()

    const open = Boolean(anchorEl);
    const id = open ? 'simulate' : undefined;

    const submitRequest = async () => {
        setSubmitting(true)
        const order = {}
        order.id = uuidv4()
        order.ref = model?.id
        order.user = currentUser?.uid
        order.amount = value
        order.charge = value * 0.1
        order.tax = value * 0.16
        order.total = value * 1.16
        order.currency = model?.currency
        order.model = model
        order.status = ""
        order.created = Date.now()
        order.updated = order.created
        exists(currentUser?.uid, model.id)
            .then(function (response) {
                setSubmitting(false);
                setError("Sorry, this model already exists in your profile. You could go and update it.")
            })
            .catch(function (err) {
                submitOrder(order)
                    .then(function (response) {
                        setSubmitting(false);
                        navigate('/property/payments/payment-details?id=' + order.id, {state: {order: order}})
                    })
                    .catch(function (err) {
                        setSubmitting(false);
                        setError(err)
                    });
            })
    }

    const handleExecute = (e) => {
        setExectute(true)
        setSimulate(false)
        setAnchorEl(e.currentTarget);
    };

    const handleSimulate = (e) => {
        setExectute(false)
        setSimulate(true)
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setValue(0)
        setAnchorEl(null);
    };

    const createComplain = () => {
        if(value > 0) {
            setAnchorEl(null);
            if(execute)
                submitRequest()
            else if(simulate)
                data?.handleValue(value)
        }
    };

    const [text, setText] = useState("");
    const [image, setImage] = useState(null);

    const handleTextChange = (e) => {
      setText(e.target.value);
    };

    const handleImageChange = (e) => {
      const selectedImage = e.target.files[0];
      setImage(selectedImage);
    }


const handleUpload = () => {
  const imageRef = storage.ref().child(`images/${image.name}`);
  imageRef.put(image).then((snapshot) => {
    console.log("Image uploaded successfully!");
    return snapshot.ref.getDownloadURL();
  }).then((imageUrl) => {
    // Now 'imageUrl' contains the URL of the uploaded image.
    // You can use this URL to display the image to users or store it in Firestore.
    
    // Add text data to Firestore along with the image URL
    db.collection('users').doc('profile').collection('complains').add({
      text: text,
      imageUrl: imageUrl,
      // other fields you want to store
    }).then(() => {
      console.log("Text data added to Firestore successfully!");
    }).catch((error) => {
      console.error("Error adding text data to Firestore:", error);
    });
  }).catch((error) => {
    console.error("Error uploading image:", error);
  });
};


  return (
    <SoftBox>
      <SoftBox mb={1}>
        <SoftTypography variant="h3" fontWeight="bold">
          { model ? model.name : "" }
        </SoftTypography>
      </SoftBox>
      <SoftBadge variant="contained" color={!model ? "success" : model.type == "Aggressive" ? "error" : model.type == "Moderate" ? "warning" : "success" } badgeContent={!model ? "" : model.type} container />
      <SoftBox mt={3} ml={0.5}>
        <SoftTypography variant="caption" fontWeight="bold">
          Description
        </SoftTypography>
      </SoftBox>
    <SoftBox color="text" fontSize="1.25rem" lineHeight={1} mb={2}>
      <SoftTypography variant="body2" color="text" verticalAlign="middle">
        { model ? model.desc : "" }
      </SoftTypography>
    </SoftBox>
        {isSubmitting
            ? <SoftBox display="flex" justifyContent="center" m="auto" mt={4}>
                <CircularProgress color="info"/>
            </SoftBox>
        : <SoftBox mt={3}>
        <SoftBox display="inline-flex" width="100%">
          <Grid item xs={12} lg={5} container mb={1.5}>
              <SoftButton aria-describedby={id}  color="light" fullWidth onClick={handleSimulate} disabled={isSubmitting}>
                  complains
              </SoftButton>
            </Grid>
            <SoftBox m={0.4} px={1}>
                <Tooltip title="This allow you to launch any kind of complains for any challenges you face." placement="bottom" arrow>
                    <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                        <Icon >priority_high</Icon>
                    </SoftButton>
                </Tooltip>
            </SoftBox>
          </SoftBox>
          <Grid item xs={12} lg={5} container>
            <SoftButton aria-describedby={id}  variant="gradient" color="info" fullWidth onClick={handleExecute} disabled={isSubmitting}>
                notices
            </SoftButton>
          </Grid>
      </SoftBox>}
      <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <SoftBox bgColor="white" px={3} py={3} mx={-2}>
                <SoftTypography variant="p" fontWeight="regular" fontSize="14px">
                    What is your complain?
                </SoftTypography>
                <SoftInput type="text"
                           onChange={handleTextChange}
                           placeholder="Type complain here..." />
                <SoftInput type="file"
                           accept="image"
                           onChange={handleImageChange} />

                <SoftBox mt={1} display="flex" justifyContent="flex-end">
                    <SoftButton color="light" size="small" onClick={handleClose}>
                        cancel
                    </SoftButton>
                    <SoftBox mx={1} my={1} />
                    <SoftButton variant="gradient" color="info" size="small" onClick={handleUpload}>
                        submit
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Popover>
        <SoftBox display="flex" justifyContent="center" mt={2} sx={{ width: "100%" }}>
            {error ? <SoftTypography color="error" fontWeight="light" variant="button">
                {error.startsWith("Firebase") ? error.replace("Firebase: ", "") : error}
            </SoftTypography> : ""}
        </SoftBox>
    </SoftBox>
  );
}

export default ModelInfo;
