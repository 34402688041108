import checkout from "layouts/property/units/new-unit/schemas/form";

const {
  formField: {
    id,
    name,
    desc,
    ror,
    risk,
    type,
    stocks,
    bonds,
    cash,
    other,
    stockWeight,
    bondWeight,
    cashWeight,
    otherWeight,
    totalWeight
  },
} = checkout;

const initialValues = {
};

export default initialValues;
