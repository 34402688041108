import {useMemo, useState} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import CancelIcon from '@mui/icons-material/Cancel';

import SoftTypography from "components/SoftTypography";
import SoftBox from "components/SoftBox";

import TenantsTableCell from "examples/Tables/TenantsTable/TenantsTableCell";

function TenantsTable({ title, rows }) {
    const renderTableCells = rows.map((row, key) => {
        const tableRows = [];
        const rowKey = `row-${key}`;

        const removeRow = ()=> {
            rows.splice(key, 1)
        }

        Object.entries(row).map(([cellTitle, cellContent]) =>
          Array.isArray(cellContent)
            ? tableRows.push(
                <TenantsTableCell
                  key={cellContent[0]}
                  title={cellTitle}
                  content={cellContent[0]}
                  image={cellContent[0]}
                  noBorder={key === rows.length - 1}
                />
              )
            : tableRows.push(
                <TenantsTableCell
                  key={cellContent}
                  title={cellTitle}
                  content={cellContent}
                  noBorder={key === rows.length - 1}
                />
              )
        );

        return <TableRow key={rowKey} sx={{ position: "relative", height: "100%", overflow: "hidden" }}>
                    {tableRows} <CancelIcon sx={{ top: "50%", transform: "translateY(100%)" }} onClick={removeRow} />
               </TableRow>;
    });

  return (
    <TableContainer sx={{ height: "100%" }}>
      <Table>
        <TableHead>
          <SoftBox component="tr" width="max-content" display="block" mb={1.5}>
            <SoftTypography variant="h6" component="td">
              {title}
            </SoftTypography>
          </SoftBox>
        </TableHead>
        <TableBody>{useMemo(() => renderTableCells, [rows])}</TableBody>
      </Table>
    </TableContainer>
  );
}

// Setting default values for the props of MarketsTable
TenantsTable.defaultProps = {
  rows: [{}],
};

// Typechecking props for the MarketsTable
TenantsTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default TenantsTable;
