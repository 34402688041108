import { auth } from './firebase'
import { db } from './firebase'
import {doc, getDoc, setDoc} from "firebase/firestore";

import { updateStatus } from './kyc'

export async function getProfile(id) {
    if(!id) id = sessionStorage.getItem("uid")
    const docRef = doc(db,"users", id,"profile", "irp")
    return await getDoc(docRef).data()
}

export async function submitProfile(values) {      
    let id = auth.currentUser?.uid;
	console.log("Upload Profile: id=" + id)
	await setDoc(doc(db, "users", id, "profile", "irp"), {
		frequency: values.frequency,
        consequence: values.consequence,
        horizon: values.horizon,
        income: values.income,
        risky: values.risky,
        past: values.past,
	  });
      return await updateStatus(id, 2)
}	
