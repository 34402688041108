// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

function DefaultMessageCard({ color, icon, title, description, button, action }) {
  return (
    <Card>
      <SoftBox p={2} mx={3} display="flex" justifyContent="center">
        <SoftBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
          <Icon fontSize="default">{icon}</Icon>
        </SoftBox>
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography display="block" variant="caption" color="text" fontWeight="regular" mt={1}>
            {description}
          </SoftTypography>
        )}
        {description && !button ? null : <Divider />}
        {button && (
          <SoftButton onClick={action}>
            {button}
          </SoftButton>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default buttons for the props of DefaultMessageCard
DefaultMessageCard.defaultProps = {
  color: "info",
  button: "",
  description: "",
  action: null
};

// Typechecking props for the DefaultMessageCard
DefaultMessageCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  action: PropTypes.any
};

export default DefaultMessageCard;
