import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadgeDot from "components/SoftBadgeDot";
import PieChart from "examples/Charts/PieChart";

import {getCurrencySymbol, numberWithCommas} from '../../../../../scripts/utils'

function ModelsChart(response) {
  const [data, setData] = useState({})
  const [model, setModel] = useState({})
  const [mine, setMine] = useState(false)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleAction = () => navigate('/property/units/unit-page?id=' + model.id, { state: { model: model } })
  const handleModel = (model) => {
    let count = 0
    let value = 0
    for (let i in model.stocks) { value += parseFloat(model.stocks[i]['value']); count++; }
    for (let i in model.bonds) { value += parseFloat(model.bonds[i]['value']); count++; }
    for (let i in model.cash) { value += parseFloat(model.cash[i]['value']); count++; }
    for (let i in model.other) { value += parseFloat(model.other[i]['value']); count++; }
    model.count = count
    model.value = value
    setModel(model)
  }

  useEffect(() => {
    setMine(response.mine)
    setLoading(response.loading)
    let weights = []

    if(response.model) {
      let stocks = 0
      let bonds = 0
      let cash = 0
      let other = 0
      for (let i in response.model.stocks) stocks += parseFloat(response.model.stocks[i]['weight'])
      for (let i in response.model.bonds) bonds += parseFloat(response.model.bonds[i]['weight'])
      // for (let i in response.model.cash) cash += parseFloat(response.model.cash[i]['weight'])
      // for (let i in response.model.other) other += parseFloat(response.model.other[i]['weight'])
      weights = [stocks, bonds]

      handleModel(response.model)
    } else
    if(response.docs) for(let i in response.docs) {
      const doc = response.docs[i]

    if (i == 0)
      handleModel(doc.data())

      let stocks = 0
      let bonds = 0
      let cash = 0
      let other = 0
      for (let i in doc.data().stocks) stocks += parseFloat(doc.data().stocks[i]['weight'])
      for (let i in doc.data().bonds) bonds += parseFloat(doc.data().bonds[i]['weight'])
      // for (let i in doc.data().cash) cash += parseFloat(doc.data().cash[i]['weight'])
      // for (let i in doc.data().other) other += parseFloat(doc.data().other[i]['weight'])
      weights = [stocks, bonds]
    }

    setData({
      labels:["Collected", "Pending"],
      datasets: {
        label: "Models",
        backgroundColors: ["info", "primary", "dark", "secondary", "warning"],
        data: weights,
      },
    })
  }, [response])
      
  return (
    <Card sx={{ overflow: "visible" }}>
      { response.hide ? ""
      : <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <SoftTypography variant="h6">Top Performing Unit</SoftTypography>
        <Tooltip title="Click button to view model details" placement="bottom" arrow>
          <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
            <Icon>priority_high</Icon>
          </SoftButton>
        </Tooltip>
      </SoftBox> }
      <SoftBox p={2} mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            {loading
            ? <SoftBox display="flex" justifyContent="center" m="auto">
                  <CircularProgress color="info"/>
                </SoftBox>
            : <PieChart chart={data} height="100%" />}
          </Grid>
          <Grid item xs={5}>
            <SoftBox px={1}>
              <SoftBox mb={0.5}>
                <SoftBadgeDot color="info" size="sm" badgeContent="Collected" />
              </SoftBox>
              <SoftBox mb={0.5}>
                <SoftBadgeDot color="primary" size="sm" badgeContent="Pending" />
              </SoftBox>
              {/* <SoftBox mb={0.5}>
                <SoftBadgeDot color="dark" size="sm" badgeContent="Cash" />
              </SoftBox>
              <SoftBox mb={0.5}>
                <SoftBadgeDot color="secondary" size="sm" badgeContent="Other" />
              </SoftBox> */}
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        { response.hide
        ? !response.value ?  "" : <SoftTypography variant="button" color="text" fontWeight="regular">
          {"Total value is "} <strong>{ (model.currency ? getCurrencySymbol(model.currency) : "$") + numberWithCommas(response.value) }</strong>
        </SoftTypography>
        : <SoftBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          { !model.name ? "" : mine
          ? <SoftTypography variant="button" color="text" fontWeight="regular">
                {model.name + " is your best performing model with "} <strong>+17%</strong> {" growth in the past month."}
              </SoftTypography>
          : <SoftTypography variant="button" color="text" fontWeight="regular">
                {model.name + " is the best performing model with "} <strong>+17%</strong> {" growth in the past month."}
              </SoftTypography>
          }
        </SoftBox> }
        { response.hide ? ""
        : <SoftBox width={{ xs: "100%", sm: "40%" }} textAlign="right" mt={{ xs: 2, sm: "auto" }}>
          { model ? <SoftButton color="light" onClick={handleAction}>{mine ? "see more" : "view"}</SoftButton> : "" }
        </SoftBox> }
      </SoftBox>
    </Card>
  );
}

export default ModelsChart;
