import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBW5vsLVO_Xh8qBKAYD5BkSQiCp631C64I",
  authDomain: "plasma-weft-256007.firebaseapp.com",
  projectId: "plasma-weft-256007",
  storageBucket: "plasma-weft-256007.appspot.com",
  messagingSenderId: "73768642057",
  appId: "1:73768642057:web:66099e3abd4b40adef3ad8",
  measurementId: "G-MG7C7SVY8M"
}

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage }