import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftEditor from "components/SoftEditor";

import PieChart from "examples/Charts/PieChart";

// NewModel page components
import FormField from "layouts/property/units/new-unit/components/FormField";

function ModelInfo({ formData }) {
  const { values, errors } = formData;
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")
  const [ror, setRor] = useState(0)
  const [risk, setRisk] = useState(0)
  const [stocks, setStocks] = useState(0)
  const [bonds, setBonds] = useState(0)
  const [cash, setCash] = useState(0)
  const [other, setOther] = useState(0)

  const modelChartData = {
    labels: ["Stocks", "Bonds", "Cash", "Other"],
    datasets: {
      backgroundColors: ["info", "primary", "dark", "secondary"],
      data: [stocks, bonds, cash, other],
    },
  }

  const handleName = (e) => {
    setName(e.target.value)
    values.name = e.target.value
  }

  const handleDesc = (v) => {
    setDesc(v)
    values.desc = v
  }

  const handleRor = (e) => {
    setRor(e.target.value)
    values.ror = e.target.value
  }

  const handleRisk = (e) => {
    setRisk(e.target.value)
    values.risk = e.target.value
  }

  const handleStocks = (e) => {
    setStocks(e.target.value)
    values.stockWeight = e.target.value
  }

  const handleBonds = (e) => {
    setBonds(e.target.value)
    values.bondWeight = e.target.value
  }

  const handleCash = (e) => {
    setCash(e.target.value)
    values.cashWeight = e.target.value
  }

  const handleOther = (e) => {
    setOther(e.target.value)
    values.otherWeight = e.target.value
  }

  return (
    <SoftBox>
      <SoftTypography variant="h5">Unit Information</SoftTypography>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <FormField type="text" label="name" value={name} onChange={handleName} placeholder="eg. Unit 1" />
            </SoftBox>
            {/* <SoftBox mb={2}>
              <FormField type="number" value={ror} onChange={handleRor} label="Expected Return" placeholder="eg. 1%" />
            </SoftBox>
            <SoftBox mb={2}>
              <FormField type="number" value={risk} onChange={handleRisk} label="Risk" placeholder="eg. 1%" />
            </SoftBox> */}
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                Description&nbsp;&nbsp;
                <SoftTypography variant="caption" fontWeight="regular" color="text">
                  (optional)
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
            <SoftEditor value={handleDesc} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox mb={2}>
              <FormField type="number" value={stocks} onChange={handleStocks} label="Monthly rent" placeholder="eg. 1" />
            </SoftBox>
            <SoftBox mb={2}>
              <FormField type="number" value={bonds} onChange={handleBonds} label="water bill" placeholder="eg. 1" />
            </SoftBox>
            <SoftBox mb={2}>
              <FormField type="number" value={cash} onChange={handleCash} label="garbage bill" placeholder="eg. 1" />
            </SoftBox>
            <SoftBox mb={2}>
              <FormField type="number" value={other} onChange={handleOther} label="security bill" placeholder="eg. 1" />
            </SoftBox>
            <SoftBox >
              <PieChart
                chart={modelChartData}
                height= "10rem"
                tooltip="See how your unit looks like"
              />
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for ModelInfo
ModelInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ModelInfo;
