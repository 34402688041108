import { db } from './firebase'
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

export async function getComplains(uid) {
	if(!uid) uid = sessionStorage.getItem("uid")
	const docRef = doc(db,"users", uid,"tenant", "complain")
	return await getDoc(docRef)
}

export async function createComplain(id, values) {
	await setDoc(doc(db, "users", id, "tenant", "complain"), values);
}

// export async function updateUnit(id, values) {
// 	await updateDoc(doc(db, "users", id, "profile", "por"), values);
// }
