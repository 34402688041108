import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import {useState} from "react";

function Header(data) {
  const [order] = useState(data?.order)

  return !order ? "" : (
    <SoftBox display="flex" justifyContent="space-between" alignItems="center">
      <SoftBox>
        <SoftBox mb={1}>
          <SoftTypography variant="h5" fontWeight="medium">
              {order?.model?.name}
          </SoftTypography>
        </SoftBox>
        <SoftTypography component="p" variant="button" fontWeight="regular" color="text">
          Order no. {order?.id}
        </SoftTypography>
      </SoftBox>
      <SoftButton variant="gradient" color="secondary">
        cancel
      </SoftButton>
    </SoftBox>
  );
}

export default Header;
