const dataTableData = {
  columns: [
    { Header: "unit", accessor: "symbol", width: "50%" },
    { Header: "tenants", accessor: "weight", width: "50%" },
    // { Header: "value", accessor: "stocks", align: "left" },
  ],

  rows: [
  ],
};

export default dataTableData;
