import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";


function Behaviour({ formData }) {
  const { formField, values, errors, touched } = formData;
  const [ risky, setRisky  ] = useState(0);
  const [ past, setPast  ] = useState(0);
  const [ prefer, setPrefer  ] = useState(0);


  const handleRisky = (event) => {
    setRisky(event.target.value);
    values.risky = event.target.value;
  }
  const handlePast = (event) => {
    setPast(event.target.value);
    values.past = event.target.value;
  }
  const handlePrefer = (event) => {
    setPrefer(event.target.value);
    values.pefer = event.target.value;
  }

  return (
    <SoftBox>
      <SoftBox lineHeight={0}>
        <SoftTypography variant="h3" fontWeight="bold" color="info">
          Get your personalized portfolio
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="regular" mt={0.5} mb={4}>
          {"Hello " + sessionStorage.getItem("firstName") + ", tell us about your behaviour. We will then build a plan that is perfect for you."}
        </SoftTypography>
      </SoftBox>
      <SoftBox mt={1.625}>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="h6"
                fontWeight="regular"
                textTransform="capitalize"
              >
                13. How risky is the stock market?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={risky} onChange={handleRisky}>
            <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Very Risky</MenuItem>
              <MenuItem value={2}>Somewhat Risky</MenuItem>
              <MenuItem value={3}>Neutral</MenuItem>
              <MenuItem value={4}>Somewhat Safe</MenuItem>
              <MenuItem value={5}>Very Safe</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                component="label"
                variant="h6"
                fontWeight="regular"
                textTransform="capitalize"
              >
                14. In the past, when faced with investment losses, what did you do?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={past} onChange={handlePast}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>Sold Investment</MenuItem>
              <MenuItem value={2}>Did Nothing</MenuItem>
              <MenuItem value={3}>Purchased More</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container mb={3} spacing={3}>
          <Grid item xs={12}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <SoftTypography
                  component="label"
                  variant="h6"
                  fontWeight="regular"
                  textTransform="capitalize"
              >
                15. For your $50,000 investment, which of the following hypothetical outcomes would you prefer?
              </SoftTypography>
            </SoftBox>
            <Select input={<SoftInput size="large" />} value={prefer} onChange={handlePrefer}>
              <MenuItem value={0}>...</MenuItem>
              <MenuItem value={1}>A guaranteed gain of $5,000.</MenuItem>
              <MenuItem value={2}>Flip a coin. Heads you gain $10,000. Tails you gain nothing.</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Behaviour
Behaviour.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Behaviour;
