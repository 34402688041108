// @mui material components
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton"
import SoftBadge from "components/SoftBadge";
import {useState} from "react";

import {timeSince, toStatus} from "../../../../../../scripts/utils";

function OrderInfo(data) {
  const [order] = useState(data?.order)

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={6}>
        <SoftBox display="flex" alignItems="center">
          <SoftBox lineHeight={1}>
            <SoftTypography variant="h6" fontWeight="medium">
              {order?.name}
            </SoftTypography>
            <SoftBox mb={2}>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                {order ? "Order was created " + timeSince(order?.created) + " ago." : ""}
              </SoftTypography>
            </SoftBox>
            <SoftBadge
              variant="gradient"
              color="success"
              size="xs"
              badgeContent={toStatus(order?.status)}
              container
            />
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        <SoftButton variant="gradient" color="info">
          contact us
        </SoftButton>
      </Grid>
    </Grid>
  );
}

export default OrderInfo;
