import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

function Sessions() {
  const [browser, setBrowser] = useState('')
  const [ipAddress, setIPAddress] = useState('')

  const actionButtonStyles = {
    "& .material-icons-round": {
      transform: `translateX(0)`,
      transition: "all 200ms cubic-bezier(0.34,1.61,0.7,1.3)",
    },

    "&:hover .material-icons-round, &:focus .material-icons-round": {
      transform: `translateX(4px)`,
    },
  };

  useEffect(() => {
    var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    var isFirefox = typeof InstallTrigger !== 'undefined';
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var isEdge = !isIE && !!window.StyleMedia;
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    var isBlink = (isChrome || isOpera) && !!window.CSS;

    if(isOpera) setBrowser("Opera")
    if(isFirefox) setBrowser("Firefox")
    if(isSafari) setBrowser("Safari")
    if(isIE) setBrowser(" Internet Explorer")
    if(isEdge) setBrowser("Edge")
    if(isChrome || true) setBrowser("Chrome")
    if(isEdgeChromium) setBrowser("Edge Chromium")
    if(isBlink) setBrowser("Blink")

    fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIPAddress(data.ip))
        .catch(error => console.log(error))
  }, []);

  return (
    <Card id="sessions">
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Sessions</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          This is a list of devices that have logged into your account. Remove those that you do not
          recognize.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3} sx={{ overflow: "auto" }}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={{ xs: "max-content", sm: "100%" }}
        >
          <SoftBox display="flex" alignItems="center">
            <SoftBox textAlign="center" color="text" px={{ xs: 0, md: 1.5 }} opacity={0.6}>
              <Icon fontSize="default">desktop_windows</Icon>
            </SoftBox>
            <SoftBox height="100%" ml={2} lineHeight={1.4} mr={2}>
              <SoftTypography display="block" variant="button" fontWeight="regular" color="text">
                {browser + " " + ipAddress}
              </SoftTypography>
              <SoftTypography variant="caption" color="text">
                Your current session
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox display="flex" alignItems="center">
            <SoftBadge
              variant="contained"
              size="xs"
              badgeContent="active"
              color="success"
              container
            />
            <SoftBox mx={2} lineHeight={1}>
              <SoftTypography variant="button" color="secondary" fontWeight="regular">
                {sessionStorage.getItem("country")}
              </SoftTypography>
            </SoftBox>
            <SoftTypography
              component="a"
              href="#"
              variant="button"
              color="info"
              fontWeight="regular"
              sx={actionButtonStyles}
            >
              See more&nbsp;
              <Icon sx={{ fontWeight: "bold", verticalAlign: "middle" }}>arrow_forward</Icon>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <Divider />
      </SoftBox>
    </Card>
  );
}

export default Sessions;
