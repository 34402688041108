import { useState } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

import TenantsTable from "examples/Tables/TenantsTable";
import FormField from "layouts/property/units/new-unit/components/FormField";

function Cash({ formData }) {
  const { values, errors } = formData;
  const [symbol, setSymbol] = useState("")
  const [weight, setWeight] = useState(0)
  const [data, setData] = useState([])
  const [table, setTable] = useState(<TenantsTable rows={data} />)

  const addCash = () => {
    if (symbol) {
      console.log("Cash: symbol=" + symbol)
      const rows = data.concat([{
        security: [symbol, ""],
        weight: weight,
      }])
      setData(rows)
      values.cash = rows;
      setTable(<TenantsTable rows={rows}/>)
    }
  }

  return (
    <SoftBox>
      <SoftTypography variant="h5">Unit</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <FormField type="text" value={symbol} onChange={(e) => setSymbol(e.target.value)} label="unit name" placeholder="eg. Ruiru Apartments" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField type="text" value={weight} onChange={(e) => setWeight(e.target.value)} label="blocks" placeholder="eg. 10" />
          </Grid>
          <Grid item mt={4.5} xs={12} sm={1}>
            <SoftButton onClick={addCash} variant="gradient" color="info" iconOnly>
              <Icon>add</Icon>
            </SoftButton>
          </Grid>
          <Grid item xs={12} lg={12}>
            {table}
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

// typechecking props for Cash
Cash.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Cash;
