import { db } from './firebase'
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

export async function getUnits(uid) {
	if(!uid) uid = sessionStorage.getItem("uid")
	const docRef = doc(db,"users", uid,"profile", "por")
	return await getDoc(docRef)
}

export async function createUnit(id, values) {
	await setDoc(doc(db, "users", id, "profile", "por"), values);
}

export async function updateUnit(id, values) {
	await updateDoc(doc(db, "users", id, "profile", "por"), values);
}
