import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// OrderDetails page components
import Header from "layouts/property/payments/payment-details/components/Header";
import OrderInfo from "layouts/property/payments/payment-details/components/OrderInfo";
import TrackOrder from "layouts/property/payments/payment-details/components/TrackOrder";
import OrderSummary from "layouts/property/payments/payment-details/components/OrderSummary";

function OrderDetails() {
  const { state } = useLocation()
  const [ order ] = useState(state?.order)
  const navigate = useNavigate()

  useEffect(() => {
    if(!order) navigate('/dashboards/basic')
  }, [order])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={7}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <SoftBox pt={2} px={2}>
                <Header order={order} />
              </SoftBox>
              <Divider />
              <SoftBox pt={1} pb={3} px={2}>
                <SoftBox mb={3}>
                  <OrderInfo order={order} />
                </SoftBox>
                <Divider />
                <SoftBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={7}>
                      <TrackOrder order={order} />
                    </Grid>
                    <Grid item xs={12} lg={5} sx={{ ml: "auto" }}>
                      <OrderSummary order={order} />
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderDetails;
