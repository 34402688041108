import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import {getCurrencySymbol, numberWithCommas} from "../../../../../../scripts/utils";
import {useState} from "react";

function OrderSummary(data) {
  const [order] = useState(data?.order)

  return !order ? "" : (
    <>
      <SoftBox mb={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Order Summary
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Model Value:
        </SoftTypography>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium">
              {(order.currency ? getCurrencySymbol(order.currency) : "$") + numberWithCommas(Number(order.amount).toFixed(2))}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Delivery:
        </SoftTypography>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium">
              {(order.currency ? getCurrencySymbol(order.currency) : "$") + numberWithCommas(order.charge.toFixed(2))}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Taxes:
        </SoftTypography>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium">
              {(order.currency ? getCurrencySymbol(order.currency) : "$") + numberWithCommas(order.tax.toFixed(2))}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mt={3}>
        <SoftTypography variant="body2" fontWeight="light" color="text">
          Total:
        </SoftTypography>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium">
              {(order.currency ? getCurrencySymbol(order.currency) : "$") + numberWithCommas(order.total.toFixed(2))}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

export default OrderSummary;
