import { useState, useEffect } from "react";
import {useNavigate} from 'react-router-dom'

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import breakpoints from "assets/theme/base/breakpoints";

// Images
import avatar from "assets/images/avatar.png";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CreditCard from "../../../../../examples/Icons/CreditCard";
import Document from "../../../../../examples/Icons/Document";
import Settings from "../../../../../examples/Icons/Settings";

function Header() {
    const navigate = useNavigate()

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        // A function that sets the orientation state of the tabs.
        function handleTabsOrientation() {
            return window.innerWidth < breakpoints.values.sm
                ? setTabsOrientation("vertical")
                : setTabsOrientation("horizontal");
        }

        /**
         The event listener that's calling the handleTabsOrientation function when resizing the window.
         */
        window.addEventListener("resize", handleTabsOrientation);

        // Call the handleTabsOrientation function to set the state with the initial value.
        handleTabsOrientation();

        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleTabsOrientation);
    }, [tabsOrientation]);

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);
        console.log(newValue)
        if(2 === newValue)
            navigate('/pages/account/settings')
    }

    return (
        <SoftBox position="relative">
            <DashboardNavbar absolute light />
            <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                minHeight="18.75rem"
                borderRadius="xl"
                bgColor="dark"
            />
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <Grid container spacing={0.5} alignItems="center">
                    <Grid item>
                        <SoftAvatar
                            src={avatar}
                            alt="profile-image"
                            variant="rounded"
                            size="xl"
                        />
                    </Grid>
                    <Grid item>
                        <SoftBox height="100%" mt={0.5} lineHeight={1}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                {sessionStorage.getItem("name")}
                            </SoftTypography>
                            <SoftTypography variant="button" color="text" fontWeight="medium">
                                {sessionStorage.getItem("role")}
                            </SoftTypography>
                        </SoftBox>
                    </Grid>
                </Grid>
            </Card>
        </SoftBox>
    );
}

export default Header;
