import { useState } from "react";

import {useNavigate} from 'react-router-dom'

// formik components
import { Formik, Form } from "formik";

// @mui material components
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import SoftAlert from "components/SoftAlert";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// NewUser page components
import Need from "layouts/pages/users/profile/components/Need";
import Ability from "layouts/pages/users/profile/components/Ability";
import Behaviour from "layouts/pages/users/profile/components/Behaviour";

// NewUser layout schemas for form and form feilds
import validations from "layouts/pages/users/profile/schemas/validations";
import form from "layouts/pages/users/profile/schemas/form";
import initialValues from "layouts/pages/users/profile/schemas/initialValues";

import {submitProfile} from "../../../../scripts/profile";

function getSteps() {
  return ["Need", "Ability", "Behaviour"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <Need formData={formData} />;
    case 1:
      return <Ability formData={formData} />;
    case 2:
      return <Behaviour formData={formData} />;
    default:
      return null;
  }
}

function NewUser() {
  const [activeStep, setActiveStep] = useState(0);
  const [touched, setTouched] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const validateForm = (values) => {
    setError('')
    let isValid = true
    if (activeStep == 0 && (!values.frequency || !values.future || !values.consequence || !values.horizon || !values.ror || !values.realistic)) {
      isValid = false
      setError('Please fill the missing fields.')
    } else
    if (activeStep == 1 && (!values.consistency || !values.income || !values.industry || !values.willing)) {
      isValid = false
      setError('Please fill the missing fields.')
    } else
    if (activeStep == 2 && (!values.risky || !values.past || !values.prefer)) {
      isValid = false
      setError('Please fill the missing fields.')
    }
    console.log("Valid", isValid)
    return isValid
  }

  const submitForm = async (values) => {
    setSubmitting(true)
    submitProfile(values)
    .then(function(response) {
      setSubmitting(false);
      navigate('/dashboards/basic')
    })
    .catch(function(err) {
      setError(err)
      setSubmitting(false);
    });
  };

  const handleSubmit = (values) => {
    if(validateForm(values)) {
      if (isLastStep) {
        submitForm(values);
      } else {
        setActiveStep(activeStep + 1);
        setTouched({});
        setSubmitting(false);
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={20}>
        <Grid container justifyContent="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <SoftBox justifyContent="center" sx={{ display: 'flex', width: "100%" }}>
              {isSubmitting ? <CircularProgress color="info" mb={2} /> 
              : error ? <SoftAlert color="error" fontWeight="light" fontSize="16px" mb={1}>
              {error.startsWith("Firebase") ? error.replace("Firebase: ", "") : error}
              </SoftAlert> : ""}
            </SoftBox>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <SoftBox p={2}>
                      <SoftBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <SoftBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          {activeStep === 0 ? (
                            <SoftBox />
                          ) : (
                            <SoftButton variant="gradient" color="light" onClick={handleBack}>
                              back
                            </SoftButton>
                          )}
                          <SoftButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {isLastStep ? "save" : "next"}
                          </SoftButton>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
