import { useState, useEffect } from "react";
import {useLocation, useNavigate} from 'react-router-dom'

// Authentication layout components
import BasicLayout from "layouts/authentication/components/SignUpLayout";

import DefaultMessageCard from "examples/Cards/InfoCards/DefaultMessageCard";

import {useAuthValue} from '../../../../AuthContext'
import {auth} from '../../../../scripts/firebase'
import {sendEmailVerification} from 'firebase/auth'

function Basic() {
    const { state } = useLocation()
    const [ kyc ] = useState(state?.kyc)
    const {currentUser} = useAuthValue()
    const [time, setTime] = useState(60)
    const {timeActive, setTimeActive} = useAuthValue()
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')
    const navigate = useNavigate()

    useEffect(() => {
      const interval = setInterval(() => {
        currentUser?.reload()
        .then(() => {
          if(currentUser?.emailVerified){
            clearInterval(interval)
            navigate('/authentication/2-step-verification/basic', { state: { kyc: kyc }})
          }
        })
        .catch((err) => {
          setError(err.message)
        })
      }, 1000)
    }, [navigate, currentUser])

    useEffect(() => {
      let interval = null
      if(timeActive && time !== 0 ){
        interval = setInterval(() => {
          setTime((time) => time - 1)
        }, 1000)
      }else if(time === 0){
        setTimeActive(false)
        setTime(60)
        clearInterval(interval)
      }
      return () => clearInterval(interval);
    }, [timeActive, time, setTimeActive])
  
    const resendEmailVerification = () => {
      setError('')
      if(auth.currentUser) {
          setSending(true)
          sendEmailVerification(auth.currentUser)
              .then(() => {
                  setSending(false)
                  setTimeActive(true)
              }).catch((err) => {
                  console.error(err)
                  setSending(false)
                  setError(err.message)
              })
      } else
          navigate('/authentication/sign-in/basic')
    }    
  
    return (
      <BasicLayout>
        <DefaultMessageCard
          icon="mail"
          title="Verify your Email Address"
          description={sending ? "Sending verification email"
              : error ? error
              : "A verification email has been sent. Your email will only receive trade-related notification and documents."}
          button= {sending ? "" : !auth.currentUser ? "Back" : "Resend"}
          action= {resendEmailVerification}
        />
      </BasicLayout>
    );
  }

export default Basic;
