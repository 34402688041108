import {Navigate} from 'react-router-dom'
import {useAuthValue} from './AuthContext'

import PropTypes from 'prop-types';

import SoftBox from "./components/SoftBox";
import CircularProgress from "@mui/material/CircularProgress";

export default function PrivateRoute({children}) {
  const {currentUser, loading} = useAuthValue()

  if (loading)
    return <SoftBox display="flex" justifyContent="center" m="auto" mt={1}>
          <CircularProgress color="info"/>
        </SoftBox>;
  else {
    if(!currentUser?.emailVerified) {
      return <Navigate to='/authentication/sign-in/basic' replace/>
    }
    if(!sessionStorage.getItem('verified')) {
      return <Navigate to='/authentication/2-step-verification/basic' replace/>
    }
    return children
  }
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
}
