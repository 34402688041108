import {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import SoftBox from "components/SoftBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";

// Overview page components
import Header from "layouts/pages/profile/components/Header";
import PlatformSettings from "layouts/pages/profile/profile-overview/components/PlatformSettings";

// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";

import {doc, getDoc} from "firebase/firestore";
import {db} from "../../../../scripts/firebase";
import {useAuthValue} from "../../../../AuthContext";

function Overview() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [role, setRole] = useState("");
    const {currentUser} = useAuthValue()

    useEffect(() => {
        const fetch = async () => {
            if(sessionStorage.getItem("name")) {
                setName(sessionStorage.getItem("name"))
                setEmail(sessionStorage.getItem("email"))
                setPhone(sessionStorage.getItem("phone"))
                setCountry(sessionStorage.getItem("country"))
                if(sessionStorage.getItem("role"))
                    setRole(sessionStorage.getItem("role"))
            } else if(currentUser) {
                const id = currentUser.uid
                const docRef = doc(db,"users",  id, "profile", "kyc")
                const kyc = await getDoc(docRef)
                setName(kyc.data()['firstName'] + ' ' + kyc.data()['lastName'])
                setEmail(kyc.data()['email'])
                setPhone(kyc.data()['phone'])
                setCountry(kyc.data()['country'])
            }
        }
        fetch()
    })

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} xl={4}>
                <ProfileInfoCard
                    title="profile information"
                    description="Your privacy is our top priority, Restoco will always keep your financial information encrypted."
                    info={{
                        fullName: name,
                        mobile: phone,
                        email: email,
                        location: country,
                    }}
                    social={[
                        {
                            link: "https://www.facebook.com/Investbantu/",
                            icon: <FacebookIcon />,
                            color: "facebook",
                        },
                        {
                            link: "https://twitter.com/Investbantu",
                            icon: <TwitterIcon />,
                            color: "twitter",
                        },
                        {
                            link: "https://www.instagram.com/Investbantu/",
                            icon: <InstagramIcon />,
                            color: "instagram",
                        },
                    ]}
                    action={{ route: "/pages/account/settings", tooltip: "Edit Profile" }}
                />
            </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid>
          <Grid item xs={12} xl={4}>
            <ProfilesList title="risk profile" profiles={profilesListData} />
          </Grid>
        </Grid>
      </SoftBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
