// @mui material components
import Grid from "@mui/material/Grid";

import SoftBox from "components/SoftBox";

function Footer() {
  return (
    <SoftBox component="footer" py={6}>
      <Grid container justifyContent="center">
      </Grid>
    </SoftBox>
  );
}

export default Footer;
