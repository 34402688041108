const form = {
  formId: "new-model-form",
  formField: {
    id: {
      name: "Id",
      label: "What is the id of the model:",
      type: "text",
      placeholder: "eg. 123",
      errorMsg: "Id of model is required.",
    },
    name: {
      name: "Name",
      label: "What is the name of the model:",
      type: "text",
      placeholder: "eg. Model 1",
      errorMsg: "Name of model is required.",
    },
    desc: {
      name: "Description",
      label: "What is the description of the model:",
      type: "text",
      placeholder: "eg. This model is aggressive",
      errorMsg: "Description of model is required.",
    },
    ror: {
      name: "Rate of Return",
      label: "What is the rate of return:",
      type: "number",
      placeholder: "eg. 1%",
      errorMsg: "RoR is required.",
    },
    risk: {
      name: "Risk",
      label: "What is the risk:",
      type: "number",
      placeholder: "eg. 1%",
      errorMsg: "Risk is required.",
    },
    type: {
      name: "Type",
      label: "What is the type of the model:",
      type: "text",
      placeholder: "eg. Aggressive",
      errorMsg: "Type of model is required.",
    },
    stockWeight: {
      name: "Stock Weight",
      label: "What is the weight of stocks:",
      type: "number",
      placeholder: "eg. 1",
      errorMsg: "Weight of stock is required.",
    },
    bondWeight: {
      name: "Bond Weight",
      label: "What is the weight of bonds:",
      type: "number",
      placeholder: "eg. 1",
      errorMsg: "Weight of bond is required.",
    },
    cashWeight: {
      name: "Cash Weight",
      label: "What is the weight of cash:",
      type: "number",
      placeholder: "eg. 1",
      errorMsg: "Weight of cash is required.",
    },
    otherWeight: {
      name: "Other Weight",
      label: "What is any other weight:",
      type: "number",
      placeholder: "eg. 1",
      errorMsg: "Other weight is required.",
    },
    totalWeight: {
      name: "Total Weight",
      label: "What is the total weight:",
      type: "number",
      placeholder: "eg. 1",
      errorMsg: "Total weight is required.",
    },
    stocks: {
      name: "Stocks",
      label: "Add stocks:",
      type: "any",
      placeholder: "eg. TSLA",
      errorMsg: "Stocks are required.",
    },
    bonds: {
      name: "Bonds",
      label: "Add bonds:",
      type: "any",
      placeholder: "eg. USD",
      errorMsg: "Bonds are required.",
    },
    cash: {
      name: "Cash",
      label: "Add cash:",
      type: "any",
      placeholder: "eg. X",
      errorMsg: "Cash are required.",
    },
    other: {
      name: "Other",
      label: "Add other:",
      type: "any",
      placeholder: "eg. X",
      errorMsg: "Other are required.",
    },
  },
};

export default form;
