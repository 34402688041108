const dataTableData = {
  columns: [
    { Header: "units", accessor: "model", width: "50%" },
    { Header: "rent:garbage:water:security", accessor: "weights", align: "left" },
    // { Header: "", accessor: "rent", align: "center", width: "20px" },
    { Header: "", accessor: "view", align: "center", width: "20px" },
  ],

  rows: [
  ],
};

export default dataTableData;
