import {useState} from "react";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview(data) {
    const [order] = useState(data?.order)

  return !order ? "" : (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Track Order
      </SoftTypography>
      <SoftBox mt={2}>
        <TimelineItem
          color="secondary"
          icon="notifications"
          title="Order created"
          dateTime={new Date(order.created).toLocaleDateString('en-US', {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}).toUpperCase()}
        />
        <TimelineItem
          color="secondary"
          icon="inventory_2"
          title="Order submitted"
          dateTime={order.timeline && order.timeline["0"] ? new Date(order.timeline["0"]).toLocaleDateString('en-US', {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}).toUpperCase() : "?"}
        />
        <TimelineItem
          color="secondary"
          icon="shopping_cart"
          title="Order processing"
          dateTime={order.timeline && order.timeline["1"] ? new Date(order.timeline["1"]).toLocaleDateString('en-US', {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}).toUpperCase() : "?"}
        />
        <TimelineItem
          color="success"
          icon="done"
          title="Order completed"
          dateTime={order.timeline && order.timeline["2"] ? new Date(order.timeline["2"]).toLocaleDateString('en-US', {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}).toUpperCase() : "?"}
        />
      </SoftBox>
    </>
  );
}

export default OrdersOverview;
