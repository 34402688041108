const base = "https://api.mybantu.us:8181/v1/"
const token = "QkBBcGlVczNyOmJrOUZzRWYxdFQ1RzNoaTNRWDRjOjQ4NDg3OTIyNzg4MTY="

export async function getStocks(symbols) {
    const body = JSON.stringify({
        p: "f",
        o: {s: symbols}
    });

    try {
        const response = await fetch(base + "stocks", {
            method: 'POST',
            body: body, // string or object
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            console.error(response.status == 403 ? "Invalid credentials" : "HTTP error " + response.status);
        } else {
            return await response.json();
        }
    } catch (e) {
        console.error(e.message);
    }
}

export async function getStock(symbol, interval) {
    const body = JSON.stringify({
        p: "f",
        o: "{\"d\": \"m\", \"i\": \"" + interval + "\", \"l\": \"21\", \"y\": \"s\"}"
    });

    try {
        const response = await fetch(base + "stocks/" + symbol, {
            method: 'POST',
            body: body, // string or object
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            console.error(response.status == 403 ? "Invalid credentials" : "HTTP error " + response.status);
        } else {
            return await response.json();
        }
    } catch (e) {
        console.error(e.message);
    }
}

export async function deleteUser(uid) {
    const body = JSON.stringify({
        p: "f",
        u: uid
    });

    try {
        const response = await fetch(base + "user/delete", {
            method: 'POST',
            body: body, // string or object
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + token
            }
        });

        if (!response.ok) {
            console.error(response.status == 403 ? "Invalid credentials" : "HTTP error " + response.status);
        } else {
            return await response.text();
        }
    } catch (e) {
        console.error(e.message);
    }
}
