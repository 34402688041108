import { db } from './firebase'
import { doc, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { createUnit } from './property'
import { createComplain } from './complain'

export async function syncKyc(user) {
	const uid = user?.uid
	const docRef = doc(db,"users",  uid,"profile", "kyc")
	const kyc = await getDoc(docRef)
	const data = kyc.data()
	console.log(data)

	if(uid) sessionStorage.setItem("uid", uid)
	if(data['firstName']) sessionStorage.setItem("name", data['firstName'] + ' ' + data['lastName'])
	if(data['firstName']) sessionStorage.setItem("firstName", data['firstName'])
	if(data['lastName']) sessionStorage.setItem("lastName", data['lastName'])
	if(data['currency']) sessionStorage.setItem("country", data['currency'])
	if(data['country']) sessionStorage.setItem("country", data['country'])
	if(data['phone']) sessionStorage.setItem('phone', data['phone']);
	if(data['email']) sessionStorage.setItem('email', data['email']);
	if(data['dob']) sessionStorage.setItem('dob', data['dob']);
	if(data['role']) sessionStorage.setItem('role', data['role'])
	else sessionStorage.setItem('role', 'Investor')
	if(data['status']) sessionStorage.setItem('status', data['status'])

	return {
		name: data['firstName'] + ' ' + data['lastName'],
		firstName: data['firstName'],
		lastName: data['lastName'],
		currency: data['currency'],
		country: data['country'],
		phone: data['phone'],
		email: data['email'],
		dob: data['dob'],
		role: data['role'],
		status: data['status'],
	}
}

export async function submitKyc(id, kyc) {
	try {
		const phoneUtil = PhoneNumberUtil.getInstance();
		kyc.phone = phoneUtil.format(phoneUtil.parse(kyc.phone, kyc.country), PhoneNumberFormat.E164)
		if (kyc.phone && kyc.phone.startsWith("+")) kyc.phone = kyc.phone.substring(1);
	} catch (err) {
		console.error(err)
		throw(err +" (" + kyc.phone + ")")
	}

	createUnit(id, {
		currency:kyc. currency,
		value: 0,
		tenants: [],
		returns: 0,
		orders: 0,
		created: Date.now()
	})

	createComplain(id, {
		returns: 0,
		orders: 0,
		created: Date.now()
	})

	await setDoc(doc(db, "users", id,  "profile", "kyc"), kyc);
}


export async function updateStatus(id, value) {
	return await updateDoc(doc(db,"users", id, "profile", "kyc"), {
		status: value,
		updated: Date.now()
	})
}

export async function deleteKyc(id) {
	const docRef = doc(db, "users", id)
	await deleteDoc(docRef)
		.then(()=>{
			console.log(id + " deleted successfully")
		}).catch((err)=> {
			console.log(err)
		})
}