import { useState, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import breakpoints from "assets/theme/base/breakpoints";

// Images
import avatar from "assets/images/avatar.png";

import {doc, getDoc} from "firebase/firestore";
import {db} from '../../../../../../scripts/firebase';
import {useAuthValue} from "../../../../../../AuthContext";

function Header({ handleType }) {
  const [name, setName] = useState("");
  const [role, setRole] = useState("Investor");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
    const {currentUser} = useAuthValue()

  useEffect(() => {
    const fetch = async () => {
      if(sessionStorage.getItem("name")) {
        setName(sessionStorage.getItem("name"))
        if(sessionStorage.getItem("role"))
          setRole(sessionStorage.getItem("role"))
      } else if(currentUser) {
      const id = currentUser.uid
      const docRef = doc(db,"users", id, "profile", "kyc")
      const kyc = await getDoc(docRef)
        setName(kyc.data()['firstName'] + ' ' + kyc.data()['lastName'])
        if(kyc.data()['role'])
          setRole(kyc.data()['role'])
      }
    }
    fetch()

    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
      setTabValue(newValue);
      handleType(newValue);
  }

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        bgColor="dark"
      />
      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <SoftAvatar
              src={avatar}
              alt="profile-image"
              variant="rounded"
              size="xl"
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {name}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {role}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ backgroundColor: "transparent" }}
              >
                <Tab label="Aggressive" />
                <Tab label="Moderate" />
                <Tab label="Conservative" />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
    </SoftBox>
  );
}

// typechecking props for Stocks
Header.propTypes = {
    handleType: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Header;
