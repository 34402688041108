import checkout from "layouts/pages/users/profile/schemas/form";

const {
  formField: {
    frequency,
    income,
    consequence,
    horizon,
    reason,
    ror,
    risky,
    past,
    save,
    willing,
    prefer,
    consistency,
    industry,
  },
} = checkout;

const initialValues = {
  [frequency.name]: "",
  [income.name]: "",
  [consequence.name]: "",
  [horizon.name]: "",
  [reason.name]: "",
  [ror.name]: "",
  [risky.name]: "",
  [past.name]: "",
  [save.name]: "",
  [willing.name]: "",
  [prefer.name]: "",
  [consistency.name]: "",
  [industry.name]: "",
};

export default initialValues;
