import {auth} from '../../../../scripts/firebase'

import {useNavigate} from 'react-router-dom'

function Basic() {
    const navigate = useNavigate()

    auth.signOut().then(function() {
      console.log('Signed Out')
      sessionStorage.clear()
      navigate('/authentication/sign-in/basic')
    }, function(error) {
      console.error('Sign Out Error', error)
      navigate('/')
    });
}

export default Basic;