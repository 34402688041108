import { useState } from "react";
import {useNavigate} from 'react-router-dom'

import SoftAlert from "components/SoftAlert";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/SignUpLayout";
import Footer from "layouts/authentication/components/Footer";

import { auth } from '../../../../scripts/firebase';

import { sendPasswordResetEmail } from "firebase/auth";

function Basic() {
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const triggerResetEmail = async () => {
    setLoading(true)
    sendPasswordResetEmail(auth, email)
        .then(() => {
          setSent(true);
          setLoading(false)
          navigate('/authentication/sign-in/basic')
          alert("Email sent")
        }).catch((err) => {
          console.error(err)
          setSent(false);
          setError(err.message)
        })
  }

  return (
    <BasicLayout>
        <SoftBox p={0} mt={2} mb={2} textAlign="left">
          <SoftBox textAlign="left">
              <SoftTypography variant="h1" color="info" fontWeight="bold" fontSize="30px" textGradient>
                Reset password
              </SoftTypography>
              <SoftTypography variant="body2" fontWeight="light" fontSize="1rem">
                Please fill in your email. A link to reset password will be sent there.
              </SoftTypography>
              <SoftTypography variant="h6">
                {sent ? <SoftAlert color="success" fontWeight="light" fontSize="16px">
                    Please check your email
                  </SoftAlert> : ""}
              </SoftTypography>
            </SoftBox>
            <SoftBox pt={3}>
              <SoftBox component="form" role="form">
                <SoftBox mb={2}>
                  <SoftInput type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                </SoftBox>
                <SoftBox mt={4} mb={1}>
                  <SoftButton variant="gradient" color="info" onClick={triggerResetEmail} disabled={loading} size="large" fullWidth>
                    { loading ? ". . ." : "Send"}
                  </SoftButton>
                </SoftBox>
                <SoftBox mt={1} textAlign="center">
                  <SoftTypography variant="button" color="text" fontWeight="regular">
                  Haven&apos;t received it?{" "}
                    <SoftTypography
                    component="a"
                    to="#"
                    variant="button"
                    color="info"
                    fontWeight="bold"
                    textGradient
                  >
                    Resend
                  </SoftTypography>
                </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" justifyContent="center" mt={2} sx={{ width: "100%" }}>
                  {error ? <SoftTypography color="error" fontWeight="light" variant="button">
                    {error.startsWith("Firebase") ? error.replace("Firebase: ", "") : error}
                  </SoftTypography> : ""}
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
      <Footer />
    </BasicLayout>
  );
}

export default Basic;
